import { IonBadge, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { observer } from "mobx-react";
import diaryStore from "../../stores/diary.store";
import myDiariesStore from "../../stores/my-diaries.store";

//style
import "./DiaryChip.css";
import { toJS } from "mobx";

const DiaryChip: React.FC<any> = ({ category }) => {
  const customIcon = category?.attributes?.icon?.data?.attributes?.url;
  const title = category?.attributes?.name;
  const id = category?.id;
  let number = category?.numberOfEntries;
  let route = `/termine-kategorie/${id}`;

  if (title === "Alle Termine") {
    diaryStore.categories.forEach((element) => {
      number = number + element.numberOfEntries;
    });
  }

  if (title === "Meine Termine") {
    route = `/meine-termine`;
    let ids = toJS(myDiariesStore.diaryIds);
    let idArr = Object.entries(ids);
    let favContent = idArr.filter(
      ([key, value]: any) =>
        value.favorite === true && value.terminOrt === diaryStore.locationQuery
    );
    number = favContent.length;
  }

  return (
    <IonItem lines="full" routerLink={route}>
      <IonThumbnail slot="start">
        <img src={customIcon}></img>
      </IonThumbnail>
      <IonLabel>{title}</IonLabel>
      <IonBadge slot="end" color="primary" className="chip">
        {number}
      </IonBadge>
    </IonItem>
  );
};

export default observer(DiaryChip);
