import {
  IonContent,
  IonItem,
  IonLabel,
  IonNote,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import "./RepoDetailCard.css";
import BackButton from "../BackButton/BackButton";
import { observer } from "mobx-react";
import repositoryStore from "../../stores/repository.store";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import TimeAgo from "javascript-time-ago";

const RepoDetailCard: React.FC = () => {
  const [entry, setEntry] = useState<any>(repositoryStore.entry.attributes);

  useEffect(() => {
    if (!repositoryStore.entry) {
      let cachedEntry: any = repositoryStore.entry;
      repositoryStore.getRepoEntry(cachedEntry.id);
    }
  }, []);

  useEffect(() => {
    if (repositoryStore.entry) {
      setEntry(
        JSON.parse(
          JSON.stringify({
            ...repositoryStore.entry.attributes,
            id: Math.random(),
          })
        )
      );
    }
  }, [repositoryStore.entry]);

  if (!entry) {
    return <div></div>;
  }

  const categoryId = entry.category?.data?.id;
  let createdDay = moment(entry.createdAt);

  const timeAgo = new TimeAgo("de");

  const renderImages = () => {
    let images = [];
    if (entry.image && entry.image.data) {
      images = entry.image.data.map((item: any) => {
        return item.attributes.url;
      });
    }

    return (
      <div className="">
        {images.length === 0 ? (
          <div
            className="img-responsive img-thumbnail ratio-4-3"
            style={{
              backgroundImage: `url(/assets/${categoryId}-fundgrube.jpg)`,
            }}
          >
            {/* <img  src={`/assets/${categoryId}-fundgrube.jpg`}></img> */}
          </div>
        ) : images.length === 1 ? (
          <div
            className="img-responsive img-thumbnail ratio-4-3"
            style={{
              backgroundImage: `url(${images[0]})`,
            }}
          ></div>
        ) : (
          <div className="">
            <Swiper
              pagination={{ clickable: true }}
              cssMode
              style={{ overflow: "hidden" }}
              spaceBetween={1}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {images.map((content: string, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    {/* <img alt="image" src={`${content}`} /> */}
                    <div
                      className="img-responsive img-thumbnail ratio-4-3"
                      style={{
                        backgroundImage: `url(${content})`,
                      }}
                    ></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={"android "} key={entry.id}>
      <BackButton />
      {renderImages()}
      <IonItem lines="none">
        <div>
          <h2 style={{ marginTop: 15 }}>{entry.title}</h2>
          <p>
            {entry.createdAt && (
              <>
                {`${timeAgo.format(createdDay.toDate())} von ${
                  entry.firstName
                } ${entry.lastName}`}
              </>
            )}
          </p>
        </div>
      </IonItem>

      <IonItem lines="none">
        <IonTextarea>{entry.description}</IonTextarea>
      </IonItem>

      <div
        style={{
          margin: "1.2em",
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h6>Preis (in Euro)</h6>
        {entry.price ? <p>{entry.price}</p> : <p>keine Angabe</p>}
      </div>

      <IonToolbar color="light">
        <IonTitle>Kontaktdaten</IonTitle>
      </IonToolbar>

      <IonItem lines="none">
        <IonLabel slot="end">
          {entry.firstName} {entry.lastName}
        </IonLabel>
        <IonNote slot="start">
          <h5>Name</h5>
        </IonNote>
      </IonItem>

      {entry.company && (
        <IonItem lines="none">
          <IonLabel slot="end">{entry.company}</IonLabel>
          <IonNote slot="start">
            <h5>Betrieb</h5>
          </IonNote>
        </IonItem>
      )}

      <a href={`mailto: ${entry.email}`} className="link">
        <IonItem lines="none">
          <IonLabel slot="end">{entry.email}</IonLabel>
          <IonNote slot="start">
            <h5>Email</h5>
          </IonNote>
        </IonItem>
      </a>

      {entry.phoneNumber && (
        <a href={`tel: ${entry.phoneNumber}`} className="link">
          <IonItem lines="none">
            <IonLabel slot="end">{entry.phoneNumber}</IonLabel>
            <IonNote slot="start">
              <h5>Telefonnummer</h5>
            </IonNote>
          </IonItem>
        </a>
      )}

      <div style={{ height: "100px" }}></div>
    </div>
  );
};
export default observer(RepoDetailCard);
