import {
	IonButton,
	IonCheckbox,
	IonCol,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonSelect,
	IonSelectOption,
	IonTextarea,
	isPlatform,
	useIonLoading,
	useIonAlert,
	IonButtons,
	IonContent,
	IonHeader,
	IonModal,
	IonTitle,
	IonToolbar,
	useIonActionSheet,
	IonProgressBar,
	IonSpinner,
} from '@ionic/react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import ICategoryDto from '../../models/IRepoCategory.dto';
import PublicTime from '../../models/PublicTime.enum';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import repositoryStore from '../../stores/repository.store';
import IFormRepoInput from '../../models/IFormRepoInput';
import { showTabBar } from '../../helpers/tabBarHandler';
import Toolbar from '../Toolbar/Toolbar';

import { uploadImage } from '../../services/Http.service';
import SafeView from '../SafeView/SafeView';
import SplitScreen from '../SplitScreen/SplitScreen';
import { platform } from 'os';

import rules from '../../data/rules';
import ReactMarkdown from 'react-markdown';

import './RepoEntryForm.css';

const options = {
	cssClass: 'my-custom-interface',
};

interface ModalScreen {
	modalHandler: CallableFunction;
}

const MAX_IMAGE_SIZE = 10 * 1024 * 1024;

const RepoEntryForm: React.FC<ModalScreen> = ({ modalHandler }: ModalScreen) => {
	const history = useHistory();
	const [presentAlert] = useIonAlert();
	const [present] = useIonActionSheet();
	const [images, setImages] = useState<any>([]);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenAGB, setIsOpenAGB] = useState(false);
	const [loading, setLoading] = useState(false);

	const categories = repositoryStore.categories;

	useEffect(() => {
		repositoryStore.loadCategories();
	}, []);

	function canDismiss() {
		return new Promise<boolean>((resolve, reject) => {
			present({
				cssClass: 'ja-nein-sheet',
				header: 'Möchten Sie den Vorgang wirklich abbrechen?',
				buttons: [
					{
						text: 'Ja',
						role: 'confirm',
					},
					{
						text: 'Nein',
						role: 'cancel',
					},
				],
				onWillDismiss: (ev) => {
					if (ev.detail.role === 'confirm') {
						resolve(modalHandler(true));
					} else {
						reject();
					}
				},
			});
		});
	}

	async function handleButtonClick(message: string) {
		presentAlert({
			header: 'Sie haben eine E-Mail erhalten an',
			subHeader: `${message}`,
			message: '<p>Bitte schauen Sie in Ihr Postfach.</p>',
			buttons: ['OK'],
		});
	}

	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		reset,
		formState,
		formState: { errors },
	} = useForm<IFormRepoInput>({
		defaultValues: {
			image: null,
			firstName: '',
			lastName: '',
			title: '',
			description: '',
			category: undefined,
			email: '',
			checkAgb: false,
			price: '',
			isPublicTime: PublicTime.WEEKS8,
			phoneNumber: '',
			company: '',
		},
	});

	const formSubmitHandler: SubmitHandler<IFormRepoInput> = async (data: IFormRepoInput) => {
		data.publishedAt = null;
		if (data.price) {
			data.price = data.price.replace(',', '.');
		}

		const response = await repositoryStore.createNewRepoEntry({
			...data,
			image: images.map((item: any) => item.id),
		});

		await handleButtonClick(`${data.email}`);

		modalHandler();
	};

	useEffect(() => {
		if (formState.isSubmitSuccessful) {
			reset();
		}
	}, [formState, reset]);

	return (
		<SplitScreen
			style="modal"
			header={
				<Toolbar
					className={isPlatform('ios') ? 'ios-top-spacing' : ''}
					icon
					title={'Eintrag erstellen'}
					hideHome
					backFnc={() => canDismiss()}
					enableBack
				/>
			}
		>
			<form onSubmit={handleSubmit(formSubmitHandler)}>
				{/* title-bar */}

				<div>
					<div
						className="phonebook-title"
						style={{ backgroundColor: 'transparent' }}
					>
						Beschreiben Sie Ihren Artikel
					</div>

					<IonItem>
						<IonLabel position="stacked">Kategorie</IonLabel>
						<Controller
							render={({ field }) => (
								<IonSelect
									interfaceOptions={options}
									placeholder="Kategorie*"
									value={field.value}
									onIonChange={(e: any) => {
										setValue('category', e.detail.value);
									}}
								>
									{categories
										.filter((e) => e.id > 1)
										.map((category: ICategoryDto) => {
											const { attributes } = category;

											return (
												<IonSelectOption
													value={category.id}
													key={category.id}
												>
													{attributes.name}
												</IonSelectOption>
											);
										})}
								</IonSelect>
							)}
							control={control}
							name="category"
							rules={{ required: 'Kategorie darf nicht leer sein' }}
						/>
					</IonItem>

					<ErrorMessage
						name="category"
						errors={errors}
						as={<div style={{ marginLeft: '15px', color: 'red' }} />}
					/>

					{/* title */}
					<IonItem>
						<IonLabel position="stacked">Titel</IonLabel>
						<IonInput
							{...register('title', {
								required: 'Geben Sie einen Titel ein',
								minLength: { value: 3, message: 'min. 3 Zeichen ' },
							})}
							type="text"
							name="title"
							placeholder="Titel*"
						></IonInput>
					</IonItem>
					<ErrorMessage
						name="title"
						errors={errors}
						as={<div style={{ marginLeft: '15px', color: 'red' }} />}
					/>

					{/* description */}
					<IonItem>
						<IonLabel position="stacked">Beschreibung</IonLabel>
						<IonTextarea
							{...register('description', {
								required: 'Geben Sie eine Beschreibung ein',
								minLength: { value: 3, message: 'min. 3 Zeichen ' },
							})}
							name="description"
							placeholder="Beschreibung*"
						></IonTextarea>
					</IonItem>
					<ErrorMessage
						name="description"
						errors={errors}
						as={<div style={{ marginLeft: '15px', color: 'red' }} />}
					/>

					{/* price */}
					<IonItem>
						<IonLabel position="stacked">Preis (in Euro)</IonLabel>
						<IonInput
							{...register('price', {
								required: false,
								// pattern: {
								//   value: /\d{1,3}(?:[.,]\d{3})*(?:[.,]\d{2})?/,
								//   message: "Gib eine gültige Zahl ein",
								// },
							})}
							type="text"
							//inputmode="numeric"
							//pattern="[0-9]*"
							name="price"
							placeholder="Eingabe optional"
						></IonInput>
					</IonItem>
					{/* <ErrorMessage
            name="price"
            errors={errors}
            as={
              <div
                style={{
                  marginLeft: "15px",
                  color: "red",
                  paddingBottom: "5px",
                }}
              />
            }
          /> */}

					<div>
						{/* image */}
						<div className="phonebook-title">
							<div>Foto hochladen</div>
							<div className="info-text">
								Max. 5 Bilder, Max. Bildgröße: 10 MB, im Seitenverhältnis 4:3 (quer) – z.B. 2000 x 1500 Pixel, die längere Seite wird sonst
								abgeschnitten
							</div>
						</div>
					</div>
					{images.length === 0 && !loading && (
						<IonItem
							lines="none"
							style={{ textAlign: 'center' }}
						>
							Es wurde noch kein Bild hochgeladen
						</IonItem>
					)}

					{images.map((image: any, key: any) => {
						return (
							<IonRow
								key={key}
								style={{ paddingTop: 5 }}
							>
								<IonItem
									key={key}
									className="preview"
									lines="none"
									mode="md"
								>
									<div className="center">
										<img
											src={image.url}
											style={{
												width: 50,
												height: 50,
												marginRight: 5,
											}}
										/>
										{image.name}
									</div>
									<div
										onClick={() => {
											setImages(images.filter((img: any) => img.name !== image.name));
										}}
										className="delete"
										slot="end"
									>
										<img
											src="/assets/icon/cross.svg"
											style={{ width: 32, height: 32 }}
										/>
									</div>
								</IonItem>
							</IonRow>
						);
					})}

					{loading && (
						<IonItem lines="none">
							<div className="spinner">
								<IonSpinner></IonSpinner>
							</div>
						</IonItem>
					)}

					<IonRow>
						<IonCol>
							<IonButton
								size="default"
								expand="block"
								fill="solid"
								disabled={images.length >= 5 ? true : false}
								className="dropzone-container"
							>
								{images.length >= 5 ? 'max. 5 Bilder' : 'Bild hochladen'}
								<input
									type="file"
									name="image"
									className="dropzone"
									multiple
									onChange={async (event) => {
										if (!event || !event.target) {
											return;
										}
										const imgFiles = event.target!.files!;
										const validatedFiles = [];
										for (let i = 0; i < imgFiles.length; i++) {
											if (imgFiles[i].size > MAX_IMAGE_SIZE) {
												presentAlert({
													subHeader: 'Max. Bildgröße beachten',
													message: `${imgFiles[i].name}`,
													buttons: ['OK'],
												});
											} else if (imgFiles[i].type !== 'image/jpeg' && imgFiles[i].type !== 'image/png') {
												presentAlert({
													subHeader: 'Nur Bilder erlaubt',
													message: `${imgFiles[i].name}`,
													buttons: ['OK'],
												});
											} else {
												validatedFiles.push(imgFiles[i]);
											}
										}
										const formData = new FormData();
										let maxImg = images.length;
										for (let j = 0; j < validatedFiles.length; j++) {
											if (maxImg >= 5) {
												break;
											}
											formData.append('files', validatedFiles[j], validatedFiles[j].name);
											maxImg++;
										}
										const files = formData.getAll('files');
										if (files.length !== 0) {
											setLoading(true);
											const imageUpload = await uploadImage(formData);
											setImages([...images, ...imageUpload.data]);
											setLoading(false);
										}
										event.target.value = '';
									}}
								/>
							</IonButton>
						</IonCol>
					</IonRow>

					<div>
						<div className="phonebook-title">Erscheinungszeitraum</div>
					</div>
					<IonSegment
						style={{
							padding: 15,
							marginBottom: 15,
							backgroundColor: 'white',
						}}
						value={getValues('isPublicTime')}
						{...register('isPublicTime', { required: true })}
						onIonChange={(e: any) => setValue('isPublicTime', e.detail.value as any)}
					>
						<IonSegmentButton value="WEEKS2">
							<IonLabel>2 Wochen</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="WEEKS4">
							<IonLabel>4 Wochen</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="WEEKS8">
							<IonLabel>8 Wochen</IonLabel>
						</IonSegmentButton>
					</IonSegment>

					{/* title-bar */}

					<div>
						<div className="phonebook-title">Angaben zur Person</div>
					</div>
					{/* first name */}
					<IonItem>
						<IonInput
							{...register('firstName', {
								required: 'Vorname darf nicht leer sein',
								minLength: { value: 3, message: 'min. 3 Zeichen ' },
							})}
							type="text"
							name="firstName"
							placeholder="Vorname*"
						></IonInput>
					</IonItem>
					<ErrorMessage
						name="firstName"
						errors={errors}
						as={<div style={{ marginLeft: '15px', color: 'red' }} />}
					/>

					{/* last name */}
					<IonItem>
						<IonInput
							{...register('lastName', {
								required: 'Nachname darf nicht leer sein',
								minLength: { value: 3, message: 'min. 3 Zeichen ' },
							})}
							type="text"
							name="lastName"
							placeholder="Nachname*"
							defaultValue=""
						></IonInput>
					</IonItem>
					<ErrorMessage
						name="lastName"
						errors={errors}
						as={<div style={{ marginLeft: '15px', color: 'red' }} />}
					/>

					{/* phone */}
					<IonItem>
						<IonInput
							{...register('phoneNumber', { required: false })}
							type="text"
							name="phoneNumber"
							placeholder="Telefon"
						></IonInput>
					</IonItem>

					{/* email */}
					<IonItem>
						<IonInput
							{...register('email', {
								required: 'Email darf nicht leer sein',
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Gib eine gültige Email ein',
								},
							})}
							type="text"
							name="email"
							placeholder="Email*"
						></IonInput>
					</IonItem>
					<ErrorMessage
						name="email"
						errors={errors}
						as={<div style={{ marginLeft: '15px', color: 'red' }} />}
					/>

					{/* company */}
					<IonItem style={{ marginBottom: 10 }}>
						<IonInput
							{...register('company', { required: false })}
							type="text"
							name="company"
							placeholder="Betrieb"
						></IonInput>
					</IonItem>

					<div>
						<div className="phonebook-title">AGB und Datenschutzerklärung</div>
					</div>
					{/* agb-checkbox */}
					<div style={{ marginTop: 15, paddingLeft: 15, display: 'flex' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								paddingRight: 5,
							}}
						>
							<Controller
								name="checkAgb"
								control={control}
								render={({ field }) => {
									return (
										<IonCheckbox
											checked={field.value}
											onIonChange={(e: any) => {
												setValue('checkAgb', e.detail.checked);
											}}
										/>
									);
								}}
								rules={{
									validate: (value) => value === true || 'Sie müssen die AGBs akzeptieren',
								}}
							/>
						</div>
						<IonCol style={{ display: 'flex' }}>
							<div
								onClick={() => setIsOpenAGB(true)}
								style={{
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									textDecoration: 'underline',
									cursor: 'pointer',
									marginRight: 4,
								}}
							>
								AGB{' '}
							</div>
							und{' '}
							<div
								onClick={() => setIsOpen(true)}
								style={{
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									textDecoration: 'underline',
									cursor: 'pointer',
									marginLeft: 4,
									marginRight: 4,
								}}
							>
								Fundgrube-Regeln{' '}
							</div>
							bestätigen*
						</IonCol>
					</div>
					<div style={{ padding: 15, paddingTop: 5 }}>
						<ErrorMessage
							name="checkAgb"
							errors={errors}
							as={<div style={{ color: 'red' }} />}
						/>
					</div>

					{/* confirm-button */}
					<IonRow style={{ padding: 20, paddingTop: 10 }}>
						{/* cancel-button */}
						<IonCol>
							<IonButton
								onClick={() => canDismiss()}
								size="default"
								expand="block"
								fill="outline"
								type="button"
								color="danger"
							>
								Abbrechen
							</IonButton>
						</IonCol>

						<IonCol>
							<IonButton
								size="default"
								expand="block"
								fill="solid"
								type="submit"
							>
								Senden
							</IonButton>
						</IonCol>
					</IonRow>
				</div>
			</form>

			<IonModal
				isOpen={isOpenAGB}
				onDidDismiss={() => {
					setIsOpenAGB(false);
				}}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>AGB</IonTitle>
						<IonButtons slot="end">
							<IonButton
								color="secondary"
								onClick={() => setIsOpenAGB(false)}
							>
								zurück
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="container">
						<p style={{ margin: ' 10px' }}>
							<ReactMarkdown>{rules.AGB}</ReactMarkdown>
						</p>
					</div>
				</IonContent>
			</IonModal>

			<IonModal
				isOpen={isOpen}
				onDidDismiss={() => {
					setIsOpen(false);
				}}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Fundgrube-Regeln</IonTitle>
						<IonButtons slot="end">
							<IonButton
								color="secondary"
								onClick={() => setIsOpen(false)}
							>
								zurück
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="container">
						<p style={{ margin: ' 10px' }}>
							<ReactMarkdown>{rules.FUNDGRUBE_REGELN}</ReactMarkdown>
						</p>
					</div>
				</IonContent>
			</IonModal>
		</SplitScreen>
	);
};

export default observer(RepoEntryForm);
