import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonLabel,
  IonThumbnail,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { IRepoEntryModel } from "../../models/IRepoEntry.model";
import moment from "moment";
import { observer } from "mobx-react";
import TimeAgo from "javascript-time-ago";

import "./RepoContentCard.css";

// English.
import de from "javascript-time-ago/locale/de";
import repositoryStore from "../../stores/repository.store";

TimeAgo.addDefaultLocale(de);

const RepoContentCard: React.FC<{ data: IRepoEntryModel }> = ({ data }) => {
  const categoryId: any = data?.attributes?.category?.data?.id;
  let createdDay = moment(data.attributes.createdAt);
  const timeAgo = new TimeAgo("de");

  const getImage = () => {
    const { image } = data.attributes;
    if (!image || !image.data || !image.data[0]) {
      return `/assets/${categoryId}-fundgrube.jpg`;
    }

    return image.data[0].attributes.url;
  };

  return (
    <IonItem lines="full" routerLink={`/fundgrube/${data.id}`}>
      <IonThumbnail className="repo-avata-image" slot="start">
        <img src={getImage()}></img>
      </IonThumbnail>
      <IonLabel>
        <div className="repo-title">{data.attributes.title}</div>
        <p style={{ fontSize: "13px" }}>
          {timeAgo.format(createdDay.toDate())} von {data.attributes.firstName}{" "}
          {data.attributes.lastName}
        </p>
      </IonLabel>
    </IonItem>
  );
};

export default observer(RepoContentCard);
