import { IonContent, IonPage } from "@ionic/react";
import { observer } from "mobx-react";
import DiaryDetailCard from "../../components/DiaryDetailCard/DiaryDetailCard";
import SplitScreen from "../../components/SplitScreen/SplitScreen";

const DiaryEntryDetailScreen: React.FC = () => {
  return (
    <IonPage>
      <SplitScreen>
        <DiaryDetailCard />
      </SplitScreen>
    </IonPage>
  );
};

export default observer(DiaryEntryDetailScreen);
