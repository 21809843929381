import qs from "qs";

const getMultipleContent = (arr: any[]) => {
  return qs.stringify(
    {
      filters: {
        id: {
          $in: arr,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
};

export default getMultipleContent;
