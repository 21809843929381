import { action, makeObservable, observable, runInAction } from "mobx";
import IDiaryCategoryDto from "../models/IDiaryCategory.dto";
import { IDiaryEntryModel } from "../models/IDiaryEntry.model";
import IFormDiaryInput from "../models/IFormDiaryInput";
import {
  createNewDiaryEntry,
  getAllDiaryEntries,
  getDiaryCategories,
  getDiaryCategory,
  getDiaryEntry,
  getFilteredDiaryEntries,
} from "../services/Http.service";

class DiaryStore {
  categories: IDiaryCategoryDto[] = [];
  category: IDiaryCategoryDto | undefined;
  entries: IDiaryEntryModel[] = [];
  entry: IDiaryEntryModel = {
    id: 0,
    attributes: {
      title: "",
      date: new Date(),
      time: "",
      location: "",
      description: "",
      image: null,
      imageURL: null,
      organizer: null,
      createdAt: null,
      updatedAt: null,
      terminOrt: null,
      organizerEmail: null,
      organizerNumber: null,
      organizerContact: null,
      organizerWeb: null,
      organizerAddress: null,
      organizerName: null,
      publishedAt: null,
      category: { data: { id: -1, attributes: { name: "" } } },
    },
    isFav: null,
    notifyMe: null,
    numberOfEntries: null,
  };
  redirectId: string = "";
  locationQuery = "LECH";

  constructor() {
    makeObservable(this, {
      redirectId: observable,
      categories: observable,
      category: observable,
      entries: observable,
      entry: observable,
      locationQuery: observable,
      loadCategories: action,
      getFilteredDiaryEntries: action,
      getAllDiaryEntries: action,
      getDiaryEntry: action,
      createNewDiaryEntry: action,
    });
  }

  setRedirectId(id: any) {
    runInAction(() => {
      this.redirectId = id;
    });
  }

  async loadCategories() {
    const tmp: any = [];
    const terminOrt = this.locationQuery;
    const response = await getDiaryCategories(terminOrt);
    if (response.status === 200) {
      //find better and dynamic solution
      for (let i = 0; i < response.data.data.length; i++) {
        response.data.data[i].numberOfEntries =
          response.data.data[i].attributes?.eventCalendarEntries?.data?.length;

        tmp.push(response.data.data[i]);

        
      }
    }
    runInAction(() => (this.categories = tmp));
  }

  setLocationQuery(query: string) {
    runInAction(() => (this.locationQuery = query));
  }

  async loadCategory(id: string) {
    const response = await getDiaryCategory(id);
    if (response.status === 200) {
      runInAction(() => (this.category = response.data.data));
    }
  }

  async getFilteredDiaryEntries(categoryId: string) {
    const terminOrt = this.locationQuery;
    const response = await getFilteredDiaryEntries(categoryId, terminOrt);
    if (response.status === 200) {
      runInAction(() => (this.entries = response.data.data));
    }
  }

  async getAllDiaryEntries() {
    const terminOrt = this.locationQuery;
    const response = await getAllDiaryEntries(terminOrt);
    if (response.status === 200) {
      runInAction(() => (this.entries = response.data.data));
    }
  }

  async getDiaryEntry(id: string) {
    const response = await getDiaryEntry(id);
    if (response.status === 200) {
      this.entry = response.data.data;
    }
  }

  async createNewDiaryEntry(data: IFormDiaryInput) {
    return await createNewDiaryEntry(data);
  }

  getDiaryEntryFromLocal(id: number) {
    let result;
    for (let entry of this.entries) {
      if (entry.id === id) {
        result = entry;
      }
    }

    return result;
  }
}

const diaryStore = new DiaryStore();

export default diaryStore;
