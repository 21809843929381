import { IonContent } from "@ionic/react";
import React from "react";

const SplitScreen = ({ children, header, extend, style }: any) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        height: "100%",
      }}
    >
      {header}
      <IonContent style={{ height: "100%", overflowY: "scroll" }}>
        {" "}
        {children}
        <div className={"tab-area " + style} style={{ height: "200px" }}></div>
      </IonContent>
    </div>
  );
};

export default SplitScreen;
