import { Redirect, Route } from "react-router-dom";
import { FCM } from "@capacitor-community/fcm";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications";

import "./App.css";

/* Pages */
import PhoneBookScreen from "./pages/PhoneBookScreen/PhoneBookScreen";
import RepositoryScreen from "./pages/RepositoryScreen/RepositoryScreen";
import HomeScreen from "./pages/HomeScreen/HomeScreen";
import DiaryScreen from "./pages/DiaryScreen/DiaryScreen";
import InfoScreen from "./pages/InfoScreen/InfoScreen";
import RepoEntryDetailScreen from "./pages/RepoEntryDetailScreen/RepoEntryDetailScreen";
import RepoContentScreen from "./pages/RepoContentScreen/RepoContentScreen";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import DiaryContentScreen from "./pages/DiaryContentScreen/DiaryContentScreen";
import DiaryEntryDetailScreen from "./pages/DiaryEntryDetailScreen/DiaryEntryDetailScreen";
import MyDiariesScreen from "./pages/MyDiariesScreen/MyDiariesScreen";
import AddDiaryEntryScreen from "./pages/AddDiaryEntryScreen/AddDiaryEntryScreen";
import PhoneBookContentScreen from "./pages/PhoneBookContentScreen/PhoneBookContentScreen";
import PhoneBookEntryDetailScreen from "./pages/PhoneBookEntryDetailScreen/PhoneBookEntryDetailScreen";
import { useEffect, useState } from "react";
import PhoneBookChildEntryScreen from "./pages/PhoneBookChildEntryScreen/PhoneBookChildEntryScreen";
import ImpressumScreen from "./pages/InfoScreens/ImpressumScreen";
import DataPolicyScreen from "./pages/InfoScreens/DataPolicy";
import AGBScreen from "./pages/InfoScreens/AGB";
import RepoRulesScreen from "./pages/InfoScreens/RepoRules";
import SearchDetailScreen from "./pages/SearchDetailScreen/SearchDetailScreen";
import SearchDetailChildScreen from "./pages/SearchDetailChildScreen/SearchDetailChildScreen";
import moment from "moment";
import LocalPushContainer from "./components/LocalPushContainer/LocalPushContainer";

setupIonicReact();

const tabs = [
  {
    tabId: "PhoneBook",
    path: "/telefonbuch",
    icon: "/assets/icon/icon-01-telefonbuch.svg",
    iconActive: "/assets/icon/icon-01-telefonbuch-aktiv.svg",
    label: "Telefonbuch",
  },
  {
    tabId: "Repository",
    path: "/fundgrube",
    icon: "/assets/icon/icon-02-fundgrube.svg",
    iconActive: "/assets/icon/icon-02-fundgrube-aktiv.svg",
    label: "Fundgrube",
  },
  {
    tabId: "Diary",
    path: "/termine",
    icon: "/assets/icon/icon-03-terminkalender.svg",
    iconActive: "/assets/icon/icon-03-terminkalender-aktiv.svg",
    label: "Termine",
  },
  {
    tabId: "Info",
    path: "/wichtiges",
    icon: "/assets/icon/icon-04-wegweiser.svg",
    iconActive: "/assets/icon/icon-04-wegweiser-aktiv.svg",
    label: "Wichtiges",
  },
];

const App: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("");

  async function listenerHandler() {
    await PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: any) => {
        try {
          await LocalNotifications.schedule({
            notifications: [
              {
                id: Math.floor(Math.random() * 1000),
                title: `${notification.title}`,
                body: notification.body,
                schedule: { at: moment().add(10, "second").toDate() },
                extra: "PUSH",
              },
            ],
          });
        } catch (error) {}
      }
    );

    await LocalNotifications.addListener(
      "localNotificationReceived",
      async (notification: any) => {
        try {
          //console.log("localnotification: ", notification);
        } catch (error) {}
      }
    ); 

    // await LocalNotifications.addListener(
    //   "localNotificationActionPerformed",
    //   async (notification: any) => {
    //     try {
    //       console.log("actions: ", notification);
    //       diaryStore.setRedirectId(notification.notification.id)
    //     } catch (error) {}

    //   }
    // );
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();

    // now you can subscribe to a specific topic
    FCM.subscribeTo({ topic: "topic_street" })
      .then((r) => console.log(`subscribed to topic`))
      .catch((err) => console.log(err));

    await listenerHandler();
  };

  useEffect(() => {
    //registerNotifications();
  }, []);

  return (
    <IonApp style={{ marginBottom: "0px" }}>
      <IonReactRouter>
        <IonTabs onIonTabsWillChange={(e) => setSelectedTab(e.detail.tab)}>
          {/* PAGES */}
          <IonRouterOutlet>
            <LocalPushContainer>
              <Route exact path="/telefonbuch">
                <PhoneBookScreen />
              </Route>
              <Route exact path="/fundgrube">
                <RepositoryScreen />
              </Route>
              <Route exact path="/termine">
                <DiaryScreen />
              </Route>
              <Route exact path="/home">
                <HomeScreen />
              </Route>
              <Route exact path="/wichtiges">
                <InfoScreen />
              </Route>

              <Route exact path="/termine-erstellung">
                <AddDiaryEntryScreen />
              </Route>
              <Route exact path="/meine-termine">
                <MyDiariesScreen />
              </Route>
              <Route exact path="/fundgrube/:id">
                <RepoEntryDetailScreen />
              </Route>
              <Route exact path="/termine/:id">
                <DiaryEntryDetailScreen />
              </Route>
              <Route exact path="/fundgrube-kategorie/:id">
                <RepoContentScreen />
              </Route>
              <Route exact path="/termine-kategorie/:id">
                <DiaryContentScreen />
              </Route>
              <Route exact path="/telefonbuch-sub/:id">
                <PhoneBookChildEntryScreen />
              </Route>
              <Route exact path="/telefonbuch-inhalt/:id">
                <PhoneBookContentScreen />
              </Route>
              <Route exact path="/telefonbuch-detail/:id">
                <PhoneBookEntryDetailScreen />
              </Route>
              <Route exact path="/suche-detail/:id">
                <SearchDetailScreen />
              </Route>
              <Route exact path="/suche-sub/:parentId/:childId">
                <SearchDetailChildScreen />
              </Route>
              <Route exact path="/">
                <Redirect to={"/home"} />
              </Route>
              <Route exact path="/impressum">
                <ImpressumScreen />
              </Route>
              <Route exact path="/agb">
                <AGBScreen />
              </Route>

              {isPlatform("ios") ? (
                <Route exact path="/datapolicy">
                  <DataPolicyScreen />
                </Route>
              ) : (
                <Route exact path="/privacypolicy">
                  <DataPolicyScreen />
                </Route>
              )}

              <Route exact path="/fundgrube-regeln">
                <RepoRulesScreen />
              </Route>
            </LocalPushContainer>
          </IonRouterOutlet>

          {/* TABS */}
          <IonTabBar
            className={isPlatform("ios") ? "tabbar-ios" : "tabbar"}
            slot="bottom"
          >
            {tabs.map((tab) => {
              const { tabId, path, label, icon, iconActive } = tab;
              const isSelected = selectedTab === tabId;
              return (
                <IonTabButton key={tabId} tab={tabId} href={path}>
                  <IonIcon
                    style={{ width: 48, height: 48 }}
                    icon={isSelected ? iconActive : icon}
                  />
                  <IonLabel style={{ fontSize: 12 }}>{label}</IonLabel>
                </IonTabButton>
              );
            })}
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
