export enum rules {
  AGB = `## Allgemeine Geschäftsbedingungen (AGB)

  Die FUNDGRUBE ist die Flohmarkt-Plattform für Lech, Zürs und Warth. Betreiber ist die
  Raiffeisenbank Lech/Arlberg registrierte Genossenschaft mit beschränkter Haftung (in der
  Folge auch Raiffeisenbank Lech genannt), Dorf 90, 6764 Lech.
  
  Wir bitten Sie um Verständnis, dass wir aus Gründen der leichteren Lesbarkeit auf Gender-
  Formulierungen verzichtet haben. Bitte verstehen Sie sämtliche personenbezogenen
  Bezeichnungen geschlechtsneutral.
  
  Die Allgemeinen Geschäftsbedingungen (AGB) gelten für die Nutzung der FUNDGRUBE-
  Plattform (App und Website) – im folgenden auch FUNDGRUBE genannt. Mit der Nutzung
  der FUNDGRUBE erklärt der Nutzer sein vollständiges Einverständnis zu den Allgemeinen
  Geschäftsbedingungen. Andernfalls ist die Nutzung der FUNDGRUBE nicht zulässig.
  Die FUNDGRUBE ist eine Flohmarkt-Plattform auf der Personen Waren verkaufen, kaufen
  tauschen oder verschenken können soweit dies nicht gegen die geltenden Gesetze, diese
  AGB oder unsere FUNDGRUBE-Regeln (siehe oben) verstößt. Die FUNDGRUBE steht
  ausschliesslich für den nicht-kommerziellen Gebrauch zur Verfügung, einzige Ausnahme ist
  die Platzierung von Jobangeboten durch Lecher Unternehmen in der entsprechenden
  FUNDGRUBE-Rubrik.
  
  Teilnahmeberechtigt sind alle Personen die in Lech, Zürs oder Warth ihren Wohnsitz oder
  ihren Arbeitsplatz haben. Nach Ermessen der Raiffeisenbank Lech ist die Teilnahme auch für
  Personen in den umliegenden Gemeinden bzw. Personen mit Bezug zu Lech, Zürs und Warth
  zulässig. Die Nutzung der FUNDGRUBE ist natürlichen Personen ab 18 Jahren gestattet, die in
  der Lage sind einen rechtsverbindlichen Vertrag abzuschließen, sowie Personen zwischen 14
  und 18 Jahren (mündige Minderjährige) mit Einverständnis der Eltern.
  
  Für die Nutzung der FUNDGRUBE-Plattform ist keine Registrierung notwendig. Anbieter
  müssen allerdings beim Einstellen eines Eintrages ihren vollen Namen und eine gültige und
  eindeutig nachvollziehbarer E-Mailadresse angeben. Im Zweifel an der Identität des
  Teilnehmers oder bei unzulässigen Inhalten kann die Raiffeisenbank Lech als Administrator
  die Veröffentlichung von Einträgen, gegebenenfalls auch ohne die Angabe von Gründen
  ablehnen. Kein Teilnehmer hat das grundsätzliche Recht auf Durchsetzung der
  Veröffentlichung eines Eintrages. Bei Missbrauch übernimmt die Raiffeisenbank Lech als
  Betreiber keine Haftung.
  
  Die Nutzung der FUNDGRUBE selbst ist kostenlos. Kosten können allerdings für den Transfer
  von Daten anfallen. Diese sind vom Netzanbieter abhängig und sind vom Nutzer zu
  entrichten. Die FUNDGRUBE App steht im Apple App Store und im im Google Play Store zum
  kostenlosen Download zur Verfügung. Zusätzlich gibt es eine kostenlose FUNDGRUBE
  Website (fundgrube.lechbank.com).
  
  Die Raiffeisenbank Lech als Betreiber der FUNDGRUBE tritt selbst nicht als Vertragspartner
  auf. Die über die FUNDGRUBE geschlossenen Verträge erfolgen ohne Ausnahme zwischen
  den Nutzern. Die Raiffeisenbank Lech stellt nur die technischen Voraussetzungen bereit.
  
  Zudem besteht keine Gewähr der Raiffeisenbank Lech auf die Richtigkeit der von Nutzern
  eingestellten und in der FUNDGRUBE veröffentlichten Inhalte.
  
  Die Raiffeisenbank Lech als Betreiber der FUNDGRUBE stellt lediglich Informationen für
  Nutzer zur Verfügung. Die Beiträge/Anzeigen in der FUNDGRUBE stellen keine
  rechtsverbindlichen Angebote des Betreibers dar. Mit der Raiffeisenbank Lech entstehen
  keinerlei Verträge, die in Zusammenhang mit dem Erwerb der in der FUNDGRUBE
  dargestellten Inhalte stehen. Ansprüche aus Verträgen zwischen FUNDGRUBE-Nutzern
  können folglich auch nicht gegenüber der Raiffeisenbank Lech geltend gemacht werden.
  
  Beim Einstellen von Angeboten mit Bild, hat der Nutzer/Anbieter dafür Sorge zu tragen, dass
  er selbst im Besitz der Bildrechte ist bzw. keine fremden Bildrechte verletzt werden. Der
  Betreiber kann bei einer Verletzung der Bildrechte nicht belangt werden.
  
  Die Nutzung bzw. das Ausdrucken von Bildern aus der Fundgrube ist nur für den
  angemessenen privaten Gebrauch erlaubt. Eine Vervielfältigung, Reproduktion,
  Veränderungen sowie eine kommerzielle Nutzung ist untersagt. Alle Inhalte der FUNDGRUBE
  unterliegen dem urheberrechtlichen Schutz nach dem Urhebergesetz.
  
  Der Betreiber übernimmt weder ein Haftung für technisch bedingte Ausfälle noch für die
  Funktionalität der Fundgrube-App. Ebenso haftet der Betreiber nicht für Schäden, die durch
  Dritte (z.B. Hacker) verursacht wurden.
  
  Im Falle von Verletzungen gegen diese AGB, Verletzungen von Bildrechten bzw. Rechten
  gegenüber Dritten, bitte wir Sie uns zu informieren: info@lechbank.com
  
  Der Betreiber behält sich das Recht vor, die Allgemeinen Geschäftsbedingungen jederzeit
  und ohne ausdrückliche Bekanntmachung zu ändern.
  Gerichtsstand ist Bludenz, Österreich.`,

  FUNDGRUBE_REGELN = `### Fundgrube-Regeln

  Die FUNDGRUBE ist eine Flohmarkt-App, die von der Raiffeisenbank Lech zur Verfügung
  gestellt wird.
  
  Mitmachen bzw. Angebote einstellen dürfen alle geschäftsfähigen Personen, die in Lech,
  Zürs und Warth leben, arbeiten oder arbeiten möchten.
  
  Jeder Anbieter in der FUNDGRUBE muss seinen vollständigen Namen sowie eine gültige und
  nachvollziehbare E-Mail-Adresse angeben und für uns eindeutig identifizierbar sein.
  
  Die FUNDGRUBE steht ausschliesslich für die nicht-kommerzielle Verwendung zur Verfügung.
  Einzige Ausnahme sind Jobangebote durch Lecher, Zürser und Warther Betriebe.
  
  Veröffentlicht werden dürfen Waren aller Art, sowie Job- und Wohnungsangebote, die den
  allgemein gültigen ethischen Richtlinien entsprechen.
  
  Das Anbieten und das Gesuch von Zweitwohnsitzen sowie Grundstücken ist in der
  FUNDGRUBE nicht gestattet.
  
  Rechtswidrige, beleidigende, diskriminierende, gewaltverherrlichende, pornografische und
  jugendgefährdende Inhalte sind unzulässig.
  
  Angebote in der FUNDGRUBE dürfen geltende Gesetze oder die Allgemeinen
  Geschäftsbedingungen nicht verletzen.
  
  Bleiben Sie immer fair! Bieten Sie angemessen und nach bestem Wissen und Gewissen an.
  
  Haben Sie viel Spass beim Kaufen, Verkaufen, Tauschen oder Verschenken!`,

  KALENDER_REGELN = `Noch keine vorhanden`,

  DATENSCHUTZ = `## PRIVACY POLICY / DATENSCHUTZ
  
  Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. In dieser Datenschutzerklärung informieren wir Sie über die Datenverarbeitung im Rahmen unserer Website.
  
  Die Nutzung unserer Website ist in der Regel ohne eigenständige Angabe Ihrer personenbezogenen Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adresse) erhoben werden, erfolgt dies stets, um die Nutzung der Website ermöglichen zu können oder auf freiwilliger Basis. Beispielsweise ist die Verarbeitung der IP-Adresse technisch bedingt und kann nicht unterbunden werden.
  
  Datenschutzrechtlicher Verantwortlicher
   Diese Website wird von der Raiffeisenbank Lech / Arlberg registrierte Genossenschaft mit beschränkter Haftung, Dorf 90, 6764 Lech betrieben.
   Alternativ können Sie sich auch gerne an unseren Datenschutzbeauftragten wenden: datenschutzbeauftragter@lechbank.com, +43 5583 2626-326
  
  **Kontaktformular**
  
   Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen und übermittelten Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Damit verfolgen wir unser berechtigtes Interesse, Ihnen bestmöglichen Service anbieten zu können sowie Ihnen Wege zu eröffnen, mit uns Informationen auszutauschen. Diese Daten werden nicht ohne Ihre Einwilligung weitergegeben.
  
  Zu den oben genannten Zwecken lassen wir Ihre personenbezogenen Daten durch folgende Dienstleister verarbeiten:
   adino.at Internetservice GmbH, [www.adino.at](http://www.adino.at/)
   und
   Boehler Brothers GmbH, [www.boehlerbrothers.com](http://www.boehlerbrothers.com/)
  
  **Cookies**

   Unsere Website verwendet so genannte Cookies. Cookies sind Textdateien, die beim Besuch der Website auf Ihrem Endgerät gespeichert werden. Wir verwenden Cookies, um anonymisierte Analysen zur Benutzung der Website durchzuführen. Cookies setzen wir weiters dazu ein, Ihnen zusätzliche Funktionen auf der Website anbieten zu können, um Interaktionen mit der Webseite einfacher zu gestalten und eine fehlerfreie Anwendung sicherzustellen (z.B. zur Erleichterung der Navigation auf einer Website oder um Ihre Präferenzen und Einstellungen für den nächsten Besuch zu speichern).
   Erforderliche Cookies: Cookies, die für die grundlegenden Funktionen der Website notwendig sind, verarbeiten wir aufgrund unseres berechtigten Interesses, Ihnen unsere Inhalte präsentieren zu können, bzw. aufgrund vertraglicher oder gesetzlicher Verpflichtungen Ihnen auf unserer Website Informationen bereitzuhalten.
   Funktionelle Cookies: Cookies, die es uns ermöglichen die Analyse der Websitenutzung vorzunehmen, verwenden wir aufgrund Ihrer bei Benutzung unserer Website erklärten Einwilligung.
  
  Marketing-Cookies: Cookies, die es uns ermöglichen, Ihnen auf Ihre Interessen abgestimmte Werbung zu unterbreiten, verwenden wir ebenfalls aufgrund Ihrer bei Benutzung unserer Website erklärten Einwilligung.
   Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Die meisten der von uns verwendeten Cookies werden nach Ende des Besuchs unserer Website wieder gelöscht (sogenannte Session-Cookies).
   Cookies können blockiert, deaktiviert oder gelöscht werden. Hierfür steht Ihnen eine Vielzahl von Werkzeugen zur Verfügung (einschließlich Browser-Steuerelemente und -Einstellungen). Informationen hierzu finden Sie im Hilfebereich des von Ihnen benutzten Webbrowsers. Bei der Deaktivierung aller von uns verwendeten Cookies kann z.B. die Darstellung der Website eingeschränkt sein.
  
  **YouTube**

   Bei YouTube handelt es sich um ein Internet-Videoportal der YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Die YouTube, LLC ist einer Tochtergesellschaft der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
   Beim Abspielen von, bei YouTube gespeicherten Videos werden mindestens folgende Daten an Google, Inc. übertragen: IP-Adresse und Cookie-ID, die spezifische Adresse der bei uns aufgerufenen Seite, Spracheinstellung des Browsers, Systemdatum und Zeit des Aufrufs sowie Kennung Ihres Browsers. Die Datenübertragung erfolgt unabhängig davon, ob Sie bei Google ein Nutzerkonto innehaben, über das Sie eingeloggt sind, oder ob für Sie kein Nutzerkonto besteht. Wenn Sie eingeloggt sind, werden diese Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung zu Ihrem Profil nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube bzw. Google, Inc. speichert diese Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer über Ihre Aktivitäten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google Inc. als Betreiber von YouTube richten müssen. Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch Google, Inc. erhalten Sie auf www.google.at/intl/de/policies/privacy/. Eine Verarbeitung der betroffenen Daten durch uns findet nicht statt.
  
  **Google Analytics**

   Bei Google Analytics handelt es sich um einen Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
   Dieser Dienst verwendet Cookies (siehe Cookies). Die durch Cookies erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Auf dieser Website wurde die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vorweg gekürzt. Nur in bestimmten Fällen wird die volle IP-Adresse an einen Server von Google in den USA oder einem sonstigen Drittstaat übertragen und dort gekürzt.
   Google nutzt diese Informationen, um Ihre Nutzung dieser Website auszuwerten, um Reports über die Aktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
   Die Speicherung der von Google Analytics benötigten Cookies kann durch eine entsprechende Einstellung Ihres Browsers verhindert werden. Allerdings kann dies dazu führen, dass Sie gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können. Die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Websote bezogenen Daten sowie deren Übermittlung und Verarbeitung durch Google können Sie verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: tools.google.com/dlpage/gaoptout?hl=de.
   Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter www.google.com/analytics/terms/de.html bzw. unter policies.google.com/?hl=de.
  
  **Google Maps / Google Earth**

   Bei Google Maps handelt es sich um einen interaktiven Kartendienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
   Bei Besuch einer Seite mit Google Map Karte erhält Google die Information über die aufgerufene Seite unserer Website und zwar unabhängig davon, ob Sie mit einem Google Account eingeloggt sind oder nicht. Eingeloggt kann Google diese Daten Ihrem Account zuordnen. Sofern Sie dies nicht wollen, müssen Sie sich vor Nutzung der Google Map Karten bei Google ausloggen. Widerspruch zu Verwendung Ihrer Daten müssen Sie direkt an Google richten.
   Die erzeugten Informationen über Ihre Benutzung dieser Website (z.B. IP-Adresse) werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter www.google.com/intl/de\_US/help/terms\_maps.html bzw. unter policies.google.com/?hl=de.
  
  **Google Web Fonts**

   Bei Google Web Fonts handelt es sich um Schriftarten für Websites der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
   Bei Besuch einer Seite mit Google Web Fonts ladet der Browser die benötigten Web Fonts in Ihren Browser-Cache. Dadurch erhält Google die Information über die aufgerufene Seite unserer Website. Sinn der Nutzung von Google Web Fonts ist eine einheitliche und ansprechende Darstellung über unsere gesamte Website. Widerspruch zu Verwendung Ihrer Daten müssen Sie direkt an Google richten.
   Die erzeugten Informationen über Ihre Benutzung dieser Website (z.B. IP-Adresse) werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter developers.google.com/fonts/faq bzw. unter policies.google.com/?hl=de.
  
  **reCAPTCHA**

   Bei reCAPTCHA handelt es sich um ein Schutzmechanismus für Formulare der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
   Bei Untersuchung innerhalb unserer Formulare, ob die Eingabe durch einen Menschen oder missbräuchlich durch maschinelle Verarbeitung erfolgt, werden die IP-Adresse und weitere für den Dienst reCAPTCHA benötigte Daten an Google übermittelt. Die im Rahmen von reCaptcha von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Widerspruch zu Verwendung Ihrer Daten müssen Sie direkt an Google richten.
   Die erzeugten Informationen über Ihre Benutzung dieser Website (z.B. IP-Adresse) werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter policies.google.com/?hl=de.
  
  **Facebook Custom Audience**

   Bei Facebook Custom Audience handelt es sich um einen Webanalysedienst der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. Facebook nutzt Cookies (siehe Cookies) und Web Beacons, um den Erfolg von Werbeanzeigen zu messen und zukünftige Werbemaßnahmen zu optimieren oder Werbeanzeigen für Besucher unserer Website anzuzeigen. Die erhobenen Daten sind für uns anonym, bieten uns also keine Rückschlüsse auf die Identitäten der Nutzer. Die Daten werden verschlüsselt zu Facebook übertragen, wodurch Facebook eine Verbindung zum jeweiligen Nutzerprofil herstellen kann und für eigene Werbezwecke entsprechend den eigenen Datenverwendungsrichtlinien verwenden kann.
   Die Speicherung der von Facebook benötigten Cookies kann durch eine entsprechende Einstellung Ihres Browsers verhindert werden. Sie können sich weiters aus Ihrem Facebook Profil ausloggen. Zudem kann das Facebook Custom Audience unter folgendem Link deaktiviert werden: www.facebook.com/ads/website\_custom\_audiences/
   Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter [www.facebook.com/about/privacy/](http://www.facebook.com/about/privacy/)
  
  **Instagram**

   Bei Instagram handelt es sich um einen Online-Dienst zum Teilen von Fotos und Videos der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.
   Bei Besuch einer Seite mit Instagram Fotos oder Videos erhält Instagram die Information über die aufgerufene Seite unserer Website und zwar unabhängig davon, ob Sie mit einem Instagram Account eingeloggt sind oder nicht. Eingeloggt kann Instagram diese Daten Ihrem Account zuordnen. Sofern Sie dies nicht wollen, müssen Sie sich vor Nutzung der Instagram Fotos oder Videos bei Instagram ausloggen. Widerspruch zu Verwendung Ihrer Daten müssen Sie direkt an Instagram richten.
   Die erzeugten Informationen über Ihre Benutzung dieser Website (z.B. IP-Adresse) werden in der Regel an einen Server von Instagram in Irland übertragen und dort gespeichert. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter [https://help.instagram.com/519522125107875](https://help.instagram.com/519522125107875)
  
  **Links zu anderen Websites und sozialen Medien**

   Auf dieser Website werden Links zu anderen Websites gesetzt. Dies erfolgt lediglich zu Informationszwecken. Diese Websites stehen nicht unter unserer Kontrolle und fallen daher nicht unter die Bestimmungen dieser Datenschutzerklärung. Sollten Sie einen Link anklicken, ist es möglich, dass der Betreiber dieser Website Daten über Sie erhebt und diese gemäß seiner Datenschutzerklärung, die von unserer abweichen kann, verwendet.
   Auf dieser Website besteht die Möglichkeit, über Links mit verschiedenen sozialen Netzwerken zu interagieren.
   Facebook, betrieben von der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA
   Twitter, betrieben von der Twitter Inc., 795 Folsom Street, Suite 600, San Francisco, CA 94107, USA
   LinkedIn, betrieben von der LinkedIn Inc., 2029 Stierlin Court, Mountain View, CA 94043, USA
   Pinterest, betrieben von der Pinterest Inc., 808 Brannan Street, San Francisco, CA 94103, USA
   YouTube, betrieben von der YouTube LLC, 901 Cherry Avenue, San Bruno, CA 94066 USA
   Wenn Sie auf einen Link eines dieser sozialen Netzwerke klicken, wird eine Verbindung zum jeweiligen Server dieses Netzwerks hergestellt. Wir haben keinen Einfluss auf den Umfang und Inhalt der Daten, die durch den Klick auf den Link an den jeweiligen Betreiber des jeweiligen sozialen Netzwerks übermittelt werden.
   Sollten Sie sich über Art, Umfang und Zweck der von den Betreibern dieser sozialen Netzwerke erhobenen Daten informieren wollen, lesen Sie bitte die Datenschutzbestimmungen des jeweiligen sozialen Netzwerks.
   Wenn Sie nicht wünschen, dass die Social Media Netzwerke Ihren Besuch unserer Website Ihrem Social Media Nutzerkonten zuordnen können, loggen Sie sich bitte aus den entsprechenden Benutzerkonten der Social Media Netzwerke aus.
  
  **Protokollierung am Webserver**

   Bei jedem Zugriff eines Nutzers auf unsere Webseite sowie bei jedem Abruf oder Versuch eines Abrufes einer Datei auf dem Server werden Daten über diesen Vorgang in einer Protokolldatei gespeichert (Logfile). Für uns ist nicht unmittelbar nachvollziehbar, welcher Nutzer welche Daten abgerufen hat. Wir versuchen auch nicht, diese Informationen zu erheben. Das wäre nur in gesetzlich geregelten Fällen und mit Hilfe Dritter (z.B. Internet Service Provider) möglich. Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert: Die IP-Adresse, der Name der abgerufenen Datei, das Datum und die Uhrzeit des Abrufs, die übertragene Datenmenge, die Meldung, ob der Abruf erfolgreich war, sowie die Meldung, warum ein Abruf gegebenenfalls fehlgeschlagen ist, der Name Ihres Internet Service Providers, gegebenenfalls das Betriebssystem, die Browsersoftware Ihres Computers sowie die Webseite, von der aus Sie uns besuchen.
   Die rechtliche Grundlage für die Verarbeitung dieser personenbezogenen Daten ist unser berechtigtes Interesse (gemäß Art 6 Abs 1 lit f DSGVO). Dieses besteht darin, Angriffe auf unsere Website erkennen, verhindern und untersuchen zu können.
   Darüber hinaus verarbeiten wir Ihre personenbezogenen Daten in Sonderfällen aufgrund der berechtigten Interessen von uns oder legitimierter Dritter an der Rechtsverfolgung oder nach Auftrag von gesetzlich dazu ermächtigten Behörden oder Gerichten.
   Wir speichern Daten zur Gewährleistung der Sicherheit unserer Homepage grundsätzlich für eine Dauer von drei Monaten. Eine längere Speicherung erfolgt nur, soweit dies erforderlich ist, um festgestellte Angriffe auf unsere Website zu untersuchen oder rechtliche Ansprüche zu verfolgen.
   Zu den oben genannten Zwecken lassen wir Ihre personenbezogenen Daten durch folgende Dienstleister verarbeiten:
   adino.at Internetservice GmbH, [www.adino.at](http://www.adino.at/)
   und
   Boehler Brothers GmbH, [www.boehlerbrothers.com](http://www.boehlerbrothers.com/)
  
  **Sicherheit**

   Sämtliche in dieser Datenschutzerklärung angeführten Auftragsverarbeiter sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln und nur im Rahmen der Leistungserbringung zu verarbeiten.
   Einige der oben genannten Empfänger befinden sich außerhalb Ihres Landes oder verarbeiten dort Ihre personenbezogenen Daten. Das Datenschutzniveau in anderen Ländern entspricht unter Umständen nicht dem Ihres Landes. Wir übermitteln Ihre personenbezogenen Daten jedoch nur in Länder, für welche die EU-Kommission entschieden hat, dass sie über ein angemessenes Datenschutzniveau verfügen oder setzen Maßnahmen, um zu gewährleisten, dass alle Empfänger ein angemessenes Datenschutzniveau haben. Hierbei bedienen wir uns auch Empfängern, welche nach den Vorgaben des „privacy shield&quot; dem Datenschutzniveau der Europäischen Union entsprechen (Beschluss der Kommission am 12. Juli 2016). Darüber hinaus  verpflichten wir auch Empfänger in Drittstaaten vertraglich dazu, Ihre Daten vertraulich zu behandeln und nur im Rahmen der Leistungserbringung zu verarbeiten.
  
  **Ihre Rechte nach der DSGVO**

   Sie sind im Rahmen des anwendbaren Rechts berechtigt, jederzeit das Recht auf Auskunft zu verlangen und damit Information über Ihre gespeicherten personenbezogenen Daten, deren Herkunft sowie Empfänger als auch den Zweck der Datenverarbeitung zu erhalten. Darüber hinaus sind sie berechtigt, Ihr Recht auf Berichtigung, Datenübertragung, Einschränkung oder Löschung dieser Daten auszuüben. Weiters haben Sie unter bestimmten Umständen das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen oder die für die Verarbeitung zuvor erteilte Einwilligung zu widerrufen. Eine Verarbeitung aufgrund einer Einwilligung erfolgt nur gemäß den in der Einwilligungserklärung festgelegten Zwecken und im darin vereinbarten Umfang. Durch den Widerruf der Einwilligung wird die Rechtsmäßigkeit von Datenverarbeitungen bis zum Zeitpunkt des Widerrufs nicht berührt.
   Sollten Sie der Meinung sein, dass bei der Verarbeitung Ihrer Daten gegen datenschutzrechtliche Bestimmungen verstoßen wird, bitten wir Sie darum, über die im Impressum angegebenen Kontaktdaten Verbindung mit uns aufzunehmen, damit wir Ihre Fragen klären können.
   Davon unberührt bleibt Ihr Recht, sich bei der für Sie zuständigen Datenschutzbehörde zu beschweren (beispielsweise via E-Mail an dsb@dsb.gv.at).
  
  Stand: Lech, September 2022`,

  IMPRESSUM = `
  ## Impressum

  Anbieterinformation gemäß § 5 e-commerce Gesetz und Angaben nach dem öst. Mediengesetz
  
  **Anbieter bzw. Herausgeber:**
  
  Raiffeisenbank Lech am Arlberg eGen
   Firmensitz (zugleich Postanschrift): Dorf 90, 6764 Lech
   Telefon: +43 5583 2626
   Fax: +43 5583 2626-150
   Bankleitzahl: 37449
   BIC-Code / SWIFT-Code: RVVGAT2B449
   Homepage: www.lechbank.com
   E-Mail: info@lechbank.com
   Firmenbuchnummer: 59694k
   Firmenbuchgericht: Landesgericht Feldkirch
   Sitz in: Lech
   GISA Nummer: 27511329
   Kammerzugehörigkeit: Wirtschaftskammer Vorarlberg
   OeNB Identnummer: 267767
   Rechtsform: registrierte Genossenschaft mit beschränkter Haftung
   UID-Nummer: ATU36047700
   Bankverbindung: Raiffeisenlandesbank Vorarlberg
   General Entity Identifier (GEI): 529900HI0747ZYOLS056
   FATCA GIIN: WYCXDJ.99999.SL.040
   Aufsichtsbehörde: FMA
  
   
  **Redaktion:**

   Raiffeisenbank Lech am Arlberg eGen
   Dorf 90, 6764 Lech
  

  **Technische Umsetzung:**

  [www.boehlerbrothers.com](http://www.boehlerbrothers.com/)
  

  **Konzept / Layout / Gestaltung:**

  [www.woelfler.com](http://www.woelfler.com/) &amp; [www.lechbank.com](http://www.lechbank.com/)
  

  **Haftungsausschluss**

  Trotz sorgfältiger inhaltlicher Kontrolle übernimmt Raiffeisenbank Lech/Arlberg keine Haftung für die Inhalte externer Links. Raiffeisenbank Lech/Arlberg übernimmt ferner keine Verantwortung für Inhalte auf Websites von deren Partnern und teilnehmenden Betrieben, auf die mittels Links verwiesen wird. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
  

  **Urheberrechte, Nutzungsbedingungen**
  
  Der Inhalt dieser Website ist urheberrechtlich geschützt. Sämtliche Inhalte, Texte, Grafiken und Fotos sind Eigentum von Raiffeisenbank Lech/Arlberg, alle Rechte vorbehalten. Bestimmte Inhalte können weiters auch den Urheberrechts- und Nutzungsbedingungen von Partnern oder Lieferanten unterliegen. Die Bereitstellung von Inhalten und Bildmaterial dieser Website auf anderen Websites ist nur mit ausdrücklicher Genehmigung von Raiffeisenbank Lech/Arlberg gestattet. Die auf dieser Website enthaltenen Angaben werden nach bestem Wissen erstellt und mit großer Sorgfalt auf ihre Richtigkeit überprüft. Trotzdem sind inhaltliche und sachliche Fehler nicht vollständig auszuschließen. Raiffeisenbank Lech/Arlberg übernimmt keinerlei Garantie und Haftung für die Richtigkeit, Aktualität und Vollständigkeit der bereitgestellten Informationen. Alle Angaben sind ohne Gewähr. Dies gilt auch für alle Links zu anderen URLs, die auf dieser Website genannt werden.
  

  **Widerspruch Werbe-Mails**

  Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Website behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
  

  **Bildnachweis**
  
  Marcel Hagen (Studio 22), Alexander Kaiser (KPA), Georg Schnell, Raiffeisenbank Lech, Shutterstock`,
}
export default rules;
