import { IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { observer } from "mobx-react";

//style
import "./InfoChip.css";

const InfoChip: React.FC<any> = ({ infoContent }) => {
  const icon = infoContent?.attributes?.icon?.data?.attributes?.url;
  const title = infoContent?.attributes?.title;
  const url = infoContent?.attributes?.url;

  return (
    <IonItem lines="full" href={url}>
      <IonThumbnail slot="start" className="info-icon">
        <img src={icon}></img>
      </IonThumbnail>
      <IonLabel>{title}</IonLabel>
    </IonItem>
  );
};

export default observer(InfoChip);
