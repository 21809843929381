import { action, makeObservable, observable } from "mobx";
import IInfoContentDto from "../models/IInfoContent.dto";
import { getInfoContent } from "../services/Http.service";

class InfoStore {
  links: IInfoContentDto[] = [];

  constructor() {
    makeObservable(this, {
      links: observable,
      loadInfoContent: action,
    });
  }

  async loadInfoContent() {
    const response = await getInfoContent();
    if (response.status === 200) {
      this.links = response.data.data;
    }
  }
}

const infoStore = new InfoStore();

export default infoStore;
