import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  isPlatform,
  RefresherEventDetail,
} from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import InfoChip from "../../components/InfoChip/InfoChip";
import SafeView from "../../components/SafeView/SafeView";
import Toolbar from "../../components/Toolbar/Toolbar";
import infoStore from "../../stores/info.store";
import "./InfoScreen.css";
import { useHistory } from "react-router";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import { platform } from "os";
import { isPlainObject } from "mobx/dist/internal";

const InfoScreen: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const [infoText, setInfoText] = useState("");

  const history = useHistory();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    await infoStore.loadInfoContent();
    setloading(false);
  }

  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    infoStore.loadInfoContent();
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  return (
    <SafeView className="info-page">
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <SplitScreen header={<Toolbar icon title={"Wichtiges"} />}>
          {loading ? (
             <div
             style={{
               display: "flex",
               justifyContent: "center",
               marginTop: "20px",
             }}
           >
             <IonSpinner />
           </div>
          ) : (
            <>
              <IonList>
                {infoStore.links.map((link) => {
                  return <InfoChip key={link?.id} infoContent={link} />;
                })}
              </IonList>
              <div className="footer">
                <IonItem className="info" lines="none">
                  <IonButton onClick={() => history.push("/agb")} fill="clear">
                    AGB
                  </IonButton>
                  <IonButton
                    onClick={() => history.push("/impressum")}
                    fill="clear"
                  >
                    Impressum
                  </IonButton>

                  {isPlatform("ios") ? (
                    <IonButton
                      onClick={() => history.push("/datapolicy")}
                      fill="clear"
                    >
                      Datenschutz
                    </IonButton>
                  ) : (
                    <IonButton
                      onClick={() => history.push("/privacypolicy")}
                      fill="clear"
                    >
                      Datenschutz
                    </IonButton>
                  )}
                </IonItem>
              </div>
            </>
          )}
        </SplitScreen>
      </IonContent>
    </SafeView>
  );
};

export default observer(InfoScreen);
