import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import homeStore from "../../stores/home.store";
import { observer } from "mobx-react";
import SafeView from "../../components/SafeView/SafeView";
import { IonSpinner } from "@ionic/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// styles
import "./HomeScreen.css";

const HomeScreen: React.FC = () => {
  let images: any = [];
  const image = homeStore.sliderContent;

  useEffect(() => {
    homeStore.loadSliderContent();
    renderImages();
  }, []);

  const renderImages = () => {
    if (image.length !== 0) {
      images = image.map((item: any) => {
        return item?.attributes?.image?.data?.attributes?.url;
      });
    }
    return true;
  };

  return (
    <SafeView>
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        <div dir="rtl">
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              height: "100vh",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 80,
            }}
          >
            <img
              src="/assets/180.png"
              style={{
                height: 80,
                width: 80,
                marginBottom: 15,
                borderRadius: 8,
              }}
            />
            <IonSpinner />
          </div>
          <Swiper
            pagination={{ clickable: false }}
            style={{ overflow: "hidden" }}
            spaceBetween={1}
            centeredSlides={true}
            initialSlide={image.length}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
          >
            {renderImages() &&
              images.map((content: string, index: string) => {
                return (
                  <SwiperSlide
                    key={index}
                    style={{ width: "100%", maxHeight: "100%" }}
                  >
                    <img alt="image" src={content} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </SafeView>
  );
};
export default observer(HomeScreen);
