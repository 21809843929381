import { IonContent, IonTitle, IonList, IonItem, IonBadge } from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router";
import PhoneBookEntry from "../../components/PhoneBookEntry/PhoneBookEntry";
import SafeView from "../../components/SafeView/SafeView";
import Search from "../../components/Search/Search";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import Toolbar from "../../components/Toolbar/Toolbar";
import phoneBookStore from "../../stores/phoneBook.store";
import SearchScreen from "../SearchScreen/SearchScreen";
import "./PhoneBookContentScreen.css";

const PhoneBookContentScreen: React.FC = () => {
  const categoryId = useParams<{ id: string }>().id;
  const categoryName = phoneBookStore?.selectedCategory?.attributes?.name;

  useEffect(() => {
    phoneBookStore.loadPhoneBookForCategory(parseInt(categoryId));
    phoneBookStore.getCategory(parseInt(categoryId));
  }, []);

  const generateHeaderName = () => {
    if (!phoneBookStore.selectedCategory) {
      return "";
    }
    const { attributes } = phoneBookStore.selectedCategory;

    if (attributes.name) {
      return attributes.name;
    }
    return "";
  };

  const renderSplitScreen = () => (
    <SplitScreen
      header={
        <>
          <Toolbar
            icon={false}
            title={generateHeaderName()}
            enableBack
            backUrl="/telefonbuch"
          />
          <Search
            placeholder={`${generateHeaderName()} durchsuchen`}
            value={phoneBookStore.query}
            onChange={(evt: any) => {
              phoneBookStore.setQ(evt.target.value);
            }}
          />
        </>
      }
    >
      {phoneBookStore.filteredCategoryEntries.length > 0 ? (
        phoneBookStore.query ? (
          <SearchScreen category={categoryId} />
        ) : (
          <IonList>
            {phoneBookStore.filteredCategoryEntries.map(
              (entry: any, id: number) => {
                if (entry.attributes.parentId) {
                  if (
                    categoryName !== "Bars" &&
                    categoryName !== "Restaurants"
                  ) {
                    return <div key={id}></div>;
                  }
                }
                return <PhoneBookEntry key={id} data={entry} />;
              }
            )}
            <div style={{ height: "150px" }}></div>
          </IonList>
        )
      ) : (
        <div className="nores">Keine Einträge vorhanden</div>
      )}
    </SplitScreen>
  );

  return (
    <SafeView className="phone-book-content-page">
      {renderSplitScreen()}
    </SafeView>
  );
};

export default observer(PhoneBookContentScreen);
