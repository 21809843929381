import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  RefresherEventDetail,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";

import repositoryStore from "../../stores/repository.store";
import RepoChip from "../../components/RepoChip/RepoChip";

// styles
import "./RepositoryScreen.css";
import SafeView from "../../components/SafeView/SafeView";
import Toolbar from "../../components/Toolbar/Toolbar";
import RepoEntryForm from "../../components/RepoEntryForm/RepoEntryForm";
import AddNewRepoEntryButton from "../../components/AddNewRepoEntryButton/AddNewRepoEntryButton";
//@ts-ignore
import { useHistory } from "react-router";
import SplitScreen from "../../components/SplitScreen/SplitScreen";

const RepositoryScreen: React.FC = () => {
  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    await repositoryStore.loadCategories();
    setLoading(false);
  }

  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    repositoryStore.loadCategories();
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  const renderSplitScreen = () => (
    <SplitScreen
      header={
        <Toolbar
          icon
          title={"Fundgrube"}
          button={
            <AddNewRepoEntryButton
              setIsOpen={() => {
                setIsOpen(true);
              }}
            />
          }
        />
      }
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <IonSpinner />
        </div>
      ) : (
        <>
          <IonList>
            {repositoryStore.categories.map((category) => {
              return <RepoChip key={category.id} category={category} />;
            })}
          </IonList>
          <div>
            <div className="phonebook-title">Infos</div>
          </div>
          <IonItem
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <IonLabel>Neuen Eintrag erstellen</IonLabel>
          </IonItem>
          <IonItem
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/fundgrube-regeln");
            }}
            lines="full"
          >
            <IonLabel>Fundgrube-Regeln</IonLabel>
          </IonItem>
        </>
      )}

      <div data-keyboard="false" data-backdrop="static">
        <IonModal
          //ref={modal}
          isOpen={isOpen}
          onDidDismiss={() => {
            setIsOpen(false);
          }}
        >
          <RepoEntryForm
            modalHandler={() => {
              setIsOpen(false);
            }}
          />
        </IonModal>
      </div>
    </SplitScreen>
  );

  return (
    <SafeView className="repo-page">
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {renderSplitScreen()}
      </IonContent>
    </SafeView>
  );
};

export default observer(RepositoryScreen);
