import React, { useRef } from "react";
import {
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCol,
  IonRow,
  IonGrid,
  useIonLoading,
  isPlatform,
  useIonAlert,
} from "@ionic/react";

import { ErrorMessage } from "@hookform/error-message";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import loginStore from "../../stores/login.store";
import ILoginFormDto from "../../models/ILoginForm.dto";
import { observer } from "mobx-react";
import IFormLoginInput from "../../models/IFormLoginInput";
import { useHistory } from "react-router-dom";

import "./LoginModalScreen.css";
import Toolbar from "../../components/Toolbar/Toolbar";
import SafeView from "../../components/SafeView/SafeView";
import SplitScreen from "../../components/SplitScreen/SplitScreen";

const options = {
  cssClass: "my-custom-interface",
};

interface LoginModalScreenProps {
  dismissModal: CallableFunction;
  success: CallableFunction;
}

const LoginModalScreen: React.FC<LoginModalScreenProps> = ({
  dismissModal,
  success,
}: LoginModalScreenProps) => {
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const clubs = loginStore.clubs;
  const modal = useRef<HTMLIonModalElement>(null);

  async function handleButtonClick(message: string) {
    presentAlert({
      header: "Info",
      message: `<h6 style={{fontSize: "30px"}} >${message}</h6>`,
      buttons: ["OK"],
    });
  }

  const formSubmitHandler: SubmitHandler<IFormLoginInput> = async (
    data: IFormLoginInput
  ) => {
    let response: any;
    try {
      response = await loginStore.sendLoginData(data);
      let club = clubs.find((element) => element.id === response.data.club.id);
      loginStore.selectedCategory = response.data.club;
      success();
    } catch (error: any) {
      handleButtonClick("Passwort nicht korrekt");
      dismissModal();
    }
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: {
      club: undefined,
      password: "",
    },
  });

  return (
    <SplitScreen
      header={
        <Toolbar
          icon
          title={"Login"}
          hideHome
          className={isPlatform("ios") && "ios-top-spacing"}
          backFnc={dismissModal}
          enableBack
        />
      }
    >
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <div>
          <IonItem lines="none">
            <div style={{ marginTop: "20px", fontSize: "20px" }}>
              <p>
                Bitte melden Sie sich an, um einen neuen Termin zu erstellen.
              </p>
            </div>
          </IonItem>
          {/* <IonItem>
            <IonLabel position="stacked">Verein wählen:</IonLabel>
            <Controller
              render={({ field }) => (
                <IonSelect
                  interfaceOptions={options}
                  placeholder="Verein*"
                  value={field.value}
                  onIonChange={(e) => {
                    setValue("club", e.detail.value);
                  }}
                >
                  {clubs.map((club: ILoginFormDto) => {
                    const { attributes } = club;

                    return (
                      <IonSelectOption value={club.id} key={club.id}>
                        {attributes.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )}
              control={control}
              name="club"
              rules={{ required: "Verein darf nicht leer sein" }}
            />
          </IonItem>
          <ErrorMessage
            name="club"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          /> */}

          <IonItem>
            <IonInput
              {...register("password", {
                required: "Passwort darf nicht leer sein",
                minLength: { value: 3, message: "min. 3 Zeichen " },
              })}
              type="password"
              name="password"
              placeholder="Passwort*"
            ></IonInput>
          </IonItem>
          <ErrorMessage
            name="password"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />

          <IonRow style={{ padding: 15 }}>
            {/* cancel-button */}
            <IonCol>
              <IonButton
                onClick={() => dismissModal()}
                size="default"
                expand="block"
                fill="outline"
                type="button"
                color="danger"
              >
                Abbrechen
              </IonButton>
            </IonCol>

            <IonCol>
              <IonButton
                size="default"
                expand="block"
                fill="solid"
                type="submit"
              >
                Anmelden
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
      </form>
    </SplitScreen>
  );
};

export default observer(LoginModalScreen);
