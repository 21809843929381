import { IonPage } from "@ionic/react";
import React from "react";
import "./SafeView.css";

const SafeView = ({ children, ignore }: any) => {
  return (
    <IonPage className={"save-view" + (ignore ? "paddingTop: 0" : "")}>
      {children}
    </IonPage>
  );
};

export default SafeView;
