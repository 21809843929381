import React from "react";
import "./Search.css";

interface SearchInterface {
  placeholder?: string;
  value: any;
  onChange: any;
}

const Search = ({ placeholder, value, onChange }: SearchInterface) => {
  return (
    <div className="search">
      <img className="search-icon" src="/assets/icon/search-icon.svg" />
      <input
        placeholder={
          placeholder ? placeholder : "Gesamtes Telefonbuch durchsuchen"
        }
        type="text"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Search;
