import { IonIcon, IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import SafeView from "../../components/SafeView/SafeView";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import Toolbar from "../../components/Toolbar/Toolbar";
import phoneBookStore from "../../stores/phoneBook.store";
import "./SearchDetailScreen.css";

const SearchDetailScreen = () => {
  const id = useParams<{ id: string }>().id;
  const history = useHistory();

  useEffect(() => {
    phoneBookStore.getImg(id);
  }, [id]);

  const res = phoneBookStore.phoneBookEntries.find(
    (element: any) => element.id.toString() === id
  );

  const entry = res.attributes;

  let image = phoneBookStore.imgURL;

  if (!entry) {
    return <div></div>;
  }

  const renderSplitScreen = () => (
    <SplitScreen
      header={
        <Toolbar
          title={entry.name + " " + (entry.nameA ? entry.nameA : "")}
          enableBack={true}
          backFnc={() => {
            history.goBack();
          }}
        />
      }
    >
      {entry?.isPremium && image !== "" ? (
        <div className="container">
          <img className="image" src={image} alt="img" />
        </div>
      ) : null}
      {/* 
        <IonItem style={{ marginTop: 15 }}>
          <div>
            {entry.postfix && <div>{entry.postfix}</div>}
            <h1>{entry.name}</h1>
          </div>
        </IonItem> */}

      {/* Kontakt */}
      {entry?.responsiblePerson && (
        <IonItem>
          <IonText class="cat-text">Kontakt</IonText>
          <IonLabel>{entry.responsiblePerson}</IonLabel>
          <IonIcon />
        </IonItem>
      )}

      {/* Telefon */}
      {entry?.phoneNumber && entry.phoneNumber !== "null" && (
        <a href={`tel: ${entry.phoneNumber}`} className="link">
          <IonItem>
            <IonText class="cat-text">Telefon</IonText>
            <IonLabel>{entry.phoneNumber}</IonLabel>
            <IonIcon
              slot="end"
              className="icon-add"
              icon="/assets/icon/icon-42-telefon.svg"
            />
          </IonItem>
        </a>
      )}

      {/* Mobil */}
      {entry?.mobileNumber && (
        <a href={`tel: ${entry.mobileNumber}`} className="link">
          <IonItem>
            <IonText class="cat-text">Mobil</IonText>
            <IonLabel>{entry.mobileNumber}</IonLabel>
            <IonIcon
              slot="end"
              className="icon-add"
              icon="/assets/icon/icon-42-telefon.svg"
            />
          </IonItem>
        </a>
      )}

      {/* Fax */}
      {entry?.fax && (
        <IonItem>
          <IonText class="cat-text">Fax</IonText>
          <IonLabel>{entry.fax}</IonLabel>
          <IonIcon />
        </IonItem>
      )}

      {/* Email */}
      {entry?.email && (
        <a href={`mailto: ${entry.email}`} className="link">
          <IonItem>
            <IonText class="cat-text">E-mail</IonText>
            <IonLabel>{entry.email}</IonLabel>
            <IonIcon
              slot="end"
              className="icon-add"
              icon="/assets/icon/icon-44-email.svg"
            />
          </IonItem>
        </a>
      )}

      {/* Homepage */}
      {entry?.homepage && (
        <a href={`${entry.homepage}`} className="link" target="_blank">
          <IonItem>
            <IonText class="cat-text">Homepage</IonText>
            <IonLabel>{entry.homepageLinkText}</IonLabel>
            <IonIcon
              slot="end"
              className="icon-add"
              icon="/assets/icon/icon-43-homepage.svg"
            />
          </IonItem>
        </a>
      )}

      {/* Address */}
      {entry?.address && (
        <IonItem
          style={{ cursor: "pointer" }}
          onClick={() => {
            const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${entry?.address}+${entry?.location}`;
            window.open(googleMapsUrl);
          }}
        >
          <IonText class="cat-text">Adresse</IonText>
          <IonText>{`${entry?.address}, ${entry?.plz} ${entry?.location}`}</IonText>
          <IonIcon
            slot="end"
            className="icon-add"
            icon="/assets/icon/icon-41-standort.svg"
          />
        </IonItem>
      )}

      {entry.phone_book_entries && entry.phone_book_entries.data.length > 0 && (
        <IonList className="mt-15">
          <h5 className="additional-entries">Weitere Einträge:</h5>
          {entry.phone_book_entries &&
            entry.phone_book_entries.data.map((entry: any) => {
              return (
                <IonItem
                  key={entry.id}
                  routerLink={`/suche-sub/${id}/${entry.id}`}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ fontWeight: "bold" }}>
                      {entry ? entry.attributes.name : ""}
                    </div>
                    <div style={{ marginLeft: 5 }}>
                      {entry ? entry.postfix : ""}
                    </div>
                  </div>
                </IonItem>
              );
            })}
        </IonList>
      )}
      <div style={{ height: "150px" }}></div>
    </SplitScreen>
  );

  return <SafeView>{renderSplitScreen()}</SafeView>;
};

export default observer(SearchDetailScreen);
