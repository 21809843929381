import { IonTitle, IonItem, IonBadge } from "@ionic/react";
import { Virtuoso } from "react-virtuoso";
import { observer } from "mobx-react";
import phoneBookStore from "../../stores/phoneBook.store";
import "./SearchScreen.css";

interface SearchScreenParams {
  category: string | number;
}

const SearchScreen: React.FC<SearchScreenParams> = ({ category }) => {
  let store: any;
  category === "all"
    ? (store = phoneBookStore.searchResults)
    : (store = phoneBookStore.searchResultsForCategory);

  return store.length > 0 ? (
    <Virtuoso
      className="ion-content-scroll-host"
      totalCount={store.length}
      style={{ height: "100%" }}
      itemContent={(index) => {
        const entry = store[index];
        const number = entry.attributes?.phone_book_entries?.data?.length;

        return (
          <div style={{ height: "56px" }}>
            <IonItem
              key={index}
              lines="full"
              routerLink={`/suche-detail/${entry.id}`}
            >
              <div style={{ display: "flex" }}>
                <div style={{ fontWeight: "bold" }}>
                  {entry.attributes ? entry.attributes.name : ""}
                </div>
                <div style={{marginLeft: 5 }}>
                  {entry.attributes ? entry.attributes.nameA : ""}
                </div>
                <div style={{ marginLeft: 5 }}>
                  {entry.attributes ? entry.attributes.postfix : ""}
                </div>
              </div>

              {number > 0 && (
                <IonBadge slot="end" color="primary">
                  {number}
                </IonBadge>
              )}
            </IonItem>
          </div>
        );
      }}
    ></Virtuoso>
  ) : (
    <div className="nores">Keine Einträge vorhanden</div>
  );
};

export default observer(SearchScreen);
