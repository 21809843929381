import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : ("http://localhost:1338" as any);

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

instance.defaults.timeout = 60000;

export default instance;
