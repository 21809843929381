import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

import "./Toolbar.css";
import { chevronBackOutline } from "ionicons/icons";
import { showTabBar } from "../../helpers/tabBarHandler";
import { Link, useHistory } from "react-router-dom";
import phoneBookStore from "../../stores/phoneBook.store";

interface ToolbarProps {
  title: string;
  button?: any;
  backUrl?: string | null;
  icon?: boolean;
  enableBack?: boolean;
  hideAll?: boolean;
  hideHome?: boolean;
  backFnc?: any;
  className?: any;
}

const Toolbar = ({
  icon,
  title,
  button,
  backUrl,
  enableBack,
  backFnc,
  hideAll,
  hideHome,
  className,
}: ToolbarProps) => {
  const history = useHistory();

  if (hideAll) {
    return (
      <IonToolbar style={{ display: "none" }} className="tool-bar"></IonToolbar>
    );
  }

  return (
    <IonToolbar className={"tool-bar " + className} color={"#c8c8c8"}>
      {enableBack && backUrl != null && (
        <IonButtons>
          <IonButton
            fill="clear"
            style={{ paddingTop: 15 }}
            onClick={() => {
              showTabBar();
              phoneBookStore.setQ("");
            }}
            routerDirection="back"
            routerLink={backUrl ? backUrl : "/home"}
          >
            <IonIcon slot="start" icon={chevronBackOutline} />
          </IonButton>
        </IonButtons>
      )}
      {enableBack && !backUrl && (
        <IonButtons>
          <IonButton
            fill="clear"
            style={{ paddingTop: 15 }}
            onClick={() => {
              if (backFnc) {
                backFnc();
              } else {
                history.goBack();
              }
            }}
            routerDirection="back"
          >
            <IonIcon slot="start" icon={chevronBackOutline} />
          </IonButton>
        </IonButtons>
      )}

      {!enableBack && !hideHome ? (
        <IonButton
          fill="clear"
          slot="start"
          style={{ paddingLeft: 0 }}
          routerDirection="back"
          href="/home"
          //routerLink={"/home"}
        >
          <IonIcon
            className="icon-home"
            icon="/assets/icon/icon-05-home-startseite.svg"
          ></IonIcon>
        </IonButton>
      ) : null}

      {button ? button : <div style={{ paddingTop: 15 }}></div>}
      <IonTitle className={enableBack ? "title-with-back" : "title"}>
       {title}
      </IonTitle>
    </IonToolbar>
  );
};

export default Toolbar;
