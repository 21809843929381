import { IonButton, IonIcon, IonModal } from "@ionic/react";
import React, { useRef } from "react";
import RepoEntryForm from "../RepoEntryForm/RepoEntryForm";

interface AddNewRepoEntryButtonProps {
  setIsOpen: any;
}

const AddNewRepoEntryButton = ({ setIsOpen }: AddNewRepoEntryButtonProps) => {
  return (
    <IonButton
      fill="clear"
      onClick={() => {
        setIsOpen();
      }}
      size="large"
      slot="end"
    >
      <IonIcon
        className="icon-add"
        icon="/assets/icon/icon-37-neuer-eintrag.svg"
      />
    </IonButton>
  );
};

export default AddNewRepoEntryButton;
