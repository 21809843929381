import { IonContent, IonIcon, IonItem } from "@ionic/react";
import BackButton from "../BackButton/BackButton";
import { observer } from "mobx-react";
import diaryStore from "../../stores/diary.store";
import moment from "moment";
import myDiariesStore from "../../stores/my-diaries.store";
import { LocalNotifications } from "@capacitor/local-notifications";
import { useEffect, useState } from "react";
import Toolbar from "../Toolbar/Toolbar";
import "./DiaryDetailCard.css";
import { useHistory, useParams } from "react-router";
import parse from "html-react-parser";

const DiaryDetailCard: React.FC = () => {
  const [isFavorite, setIsFav] = useState(false);
  const [notification, setNotification] = useState(false);
  const history = useHistory();

  const id = parseInt(useParams<{ id: string }>().id);
  let url;
  const entry = diaryStore.entry.attributes;
  const categoryId = entry?.category?.data?.id;

  if (entry.organizerWeb) {
    url = removeHttp(entry.organizerWeb);
  }

  let date = moment(entry.date + "T" + entry.time + ".000");
  const now = moment(date).subtract(12, "hours");
  const getSettings = async (id: number) => {
    const mydiary = await myDiariesStore.getMySettingsOfDiary(id);
    setIsFav(mydiary?.favorite);
    setNotification(mydiary?.notifyMe);
  };

  useEffect(() => {
    diaryStore?.getDiaryEntry("" + id);
    getSettings(id);
  }, [id]);

  function removeHttp(url: any) {
    if (url.startsWith("https://")) {
      const https = "https://";
      return url.slice(https.length);
    }

    if (url.startsWith("http://")) {
      const http = "http://";
      return url.slice(http.length);
    }

    return url;
  }

  const isExpired = now.diff(new Date()) <= 0;
  const notificationHandler = async () => {
    if (isExpired) {
      return;
    }
    if (!notification) {
      // add notification

      await LocalNotifications.schedule({
        notifications: [
          {
            id: id ? id : Math.floor(Math.random() * 1000),
            title: `${entry.title}`,
            body: `Erinnerung ${date.format("HH:mm DD.MM.YYYY")}Uhr in ${
              entry.location
            }`,
            largeBody: `Erinnerung ${date.format("HH:mm DD.MM.YYYY")} in ${
              entry.location
            }`,
            schedule: { at: now.toDate() },
          },
        ],
      });
    } else {
      // remove notification
      let result = await LocalNotifications.getPending();
      let index = result.notifications.findIndex(
        (notification) => notification.id === id
      );
      result.notifications.splice(index, 1);
    }

    myDiariesStore.setNotifyMe(id, !notification);
    setNotification(!notification);
  };

  const isFavHandler = async () => {
    myDiariesStore.setFavorite(id, !isFavorite,entry.terminOrt );
    setIsFav(!isFavorite);
  };

  const image = entry.image?.data?.attributes?.url;
  const imageURL = entry?.imageURL;
  return (
    <IonContent>
      <div>
        <Toolbar icon title="" hideAll />
      </div>

      <div className="">
        <BackButton />

        {!image ? (
          imageURL ? (
            <div
              className="img-responsive img-thumbnail ratio-4-3"
              style={{
                backgroundImage: `url(${imageURL})`,
              }}
            ></div>
          ) : (
            <div
              className="img-responsive img-thumbnail ratio-4-3"
              style={{
                backgroundImage: `url(/assets/${categoryId}-termine.jpg)`,
              }}
            ></div>
          )
        ) : (
          <div
            className="img-responsive img-thumbnail ratio-4-3"
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></div>

          // <img
          //   src={image ? image : `/assets/${categoryId}-termine.jpg`}
          //   alt="img"
          // />

          // <div
          //   className="img-responsive img-thumbnail ratio-4-3"
          //   style={{
          //     backgroundImage: `${imageURL}`,
          //   }}
          // ></div>
        )}
      </div>

      <IonItem lines="none" style={{ marginBottom: 15 }}>
        <div>
          <h2 style={{ marginTop: 15 }}>{entry.title}</h2>
          {date.isValid() && (
            <div className="lane">
              <IonIcon
                slot="end"
                className="lane-icon"
                icon={"/assets/icon/icon-03-terminkalender.svg"}
              ></IonIcon>
              <p className="center" style={{ marginRight: "15px" }}>
                {date.format("DD.MM.YYYY")}
              </p>
              {date.format("HH:mm") !== "00:00" && (
                <p className="center">{date.format("HH:mm")} Uhr</p>
              )}
            </div>
          )}
          <div className="lane">
            <IonIcon
              slot="end"
              className="lane-icon"
              icon={"/assets/icon/icon-41-standort.svg"}
            ></IonIcon>
            <p className="center">{`${entry.location}`}</p>
          </div>
        </div>
      </IonItem>

      <div style={{ margin: 15, display: "flex" }}>
        <div
          className="btn-container"
          style={{
            
            margin: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "space-around",
            borderColor: isFavorite ? "#fbf315" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            isFavHandler();
          }}
        >
          <IonIcon
            style={{ marginRight: 10 }}
            icon={
              isFavorite
                ? "/assets/icon/icon-34-stern-aktiv.svg"
                : "/assets/icon/icon-33-stern-inaktiv.svg"
            }
          ></IonIcon>
          <div>{isFavorite ? "Termin löschen" : "Termin speichern"}</div>
        </div>
        <div style={{width: 25,}} ></div>

        <div
          className={isExpired ? "btn-disabled" : "btn-container"}
          style={{
            margin: 2,
            display: "flex",
            justifyContent: "center",
            alignContent: "space-between",
            borderColor: notification ? "#fbf315" : "",
            cursor: isExpired ? "default" : "pointer",
          }}
          onClick={
            isExpired
              ? () => {}
              : () => {
                  notificationHandler();
                }
          }
        >
          <IonIcon
            style={{ marginRight: 10 }}
            icon={
              notification
                ? "/assets/icon/icon-36-glocke-aktiv.svg"
                : isExpired
                ? "/assets/icon/icon-35-glocke-inaktiv.svg"
                : "/assets/icon/icon-35-glocke-inaktiv-black.svg"
            }
          ></IonIcon>
          {notification ? " Erinnerung deaktivieren" : "Erinnerung aktivieren"}
        </div>
      </div>

      {entry.description && (
        <IonItem lines="none" style={{ marginBottom: "10px"}} className="description">
          <div
            dangerouslySetInnerHTML={{
              //__html: entry.description.replace(/\n/g, "<br/>")
              __html: entry.description.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, ""),
            }}
          />
        </IonItem>
      )}

      <IonItem lines="none" style={{ marginBottom: 10 }}>
        <div style={{ margin: "0px", padding: "0px" }}>
          {/* name */}
          {entry.organizerName && (
            <a className="link" style={{ cursor: "default" }}>
              <div className="lane">
                {/* <IonIcon slot="end" className="lane-icon" icon={""}></IonIcon> */}
                <p className="center">{`${entry.organizerName}`}</p>
              </div>
            </a>
          )}

          {/* contact person */}
          {entry.organizerContact &&
            (entry.organizerName !== entry.organizerContact ? (
              <a className="link" style={{ cursor: "default" }}>
                <div className="lane">
                  {/* <IonIcon slot="end" className="lane-icon" icon={""}></IonIcon> */}
                  <p className="center">{`${entry.organizerContact}`}</p>
                </div>
              </a>
            ) : null)}

          {/* number */}
          {entry.organizerNumber && (
            <a href={`tel: ${entry.organizerNumber}`} className="link">
              <div className="lane">
                {/* <IonIcon
                  slot="end"
                  className="lane-icon"
                  icon={"/assets/icon/icon-42-telefon.svg"}
                ></IonIcon> */}
                <p className="center">{`${entry.organizerNumber}`}</p>
              </div>
            </a>
          )}

          {/* email */}
          {entry.organizerEmail && (
            <a href={`mailto: ${entry.organizerEmail}`} className="link">
              <div className="lane">
                {/* <IonIcon
                  slot="end"
                  className="lane-icon"
                  icon={"/assets/icon/icon-44-email.svg"}
                ></IonIcon> */}
                <p
                  style={{ textDecoration: "underline" }}
                  className="center"
                >{`${entry.organizerEmail}`}</p>
              </div>
            </a>
          )}

          {/* web */}
          {entry.organizerWeb && (
            <a href={"https://" + url} className="link">
              <div className="lane">
                {/* <IonIcon
                  slot="end"
                  className="lane-icon"
                  icon={"/assets/icon/icon-43-homepage.svg"}
                ></IonIcon> */}
                <p style={{ textDecoration: "underline" }} className="center">
                  {url}
                </p>
              </div>
            </a>
          )}

          {/* address */}
          {entry.organizerAddress && (
            <a className="link" style={{ cursor: "default" }}>
              <div className="lane">
                {/* <IonIcon
                  slot="end"
                  className="lane-icon"
                  icon={"/assets/icon/icon-41-standort.svg"}
                ></IonIcon> */}
                <p className="center">{`${entry.organizerAddress.replaceAll(
                  "undefined",
                  ""
                )}`}</p>
              </div>
            </a>
          )}
        </div>
      </IonItem>
    </IonContent>
  );
};
export default observer(DiaryDetailCard);
