import { ErrorMessage } from "@hookform/error-message";
import {
  IonButton,
  IonCol,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  isPlatform,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import phoneBookStore from "../../stores/phoneBook.store";
import SafeView from "../SafeView/SafeView";
import SplitScreen from "../SplitScreen/SplitScreen";
import Toolbar from "../Toolbar/Toolbar";

import "./CreatePhoneBookEntryModal.css";

interface CreatePhoneBookEntryModalProps {
  onClose: any;
}

const CreatePhoneBookEntryModal = ({
  onClose,
}: CreatePhoneBookEntryModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: "",
      mobileNumber: "",
      phoneNumber: "",
      responsiblePerson: "",
    },
  });
  const [presentAlert] = useIonAlert();

  async function handleButtonClick(message: string) {
    presentAlert({
      header: `${message}`,
      buttons: ["OK"],
    });
  }

  const formSubmitHandler: SubmitHandler<any> = async (data: any) => {
    await phoneBookStore.createPhoneBookEntry(data);
    await handleButtonClick(`Eintrag wurde gesendet`);
    onClose();
  };
  return (
    <SplitScreen
      style="modal"
      header={
        <Toolbar
          className={isPlatform("ios") && "ios-top-spacing"}
          icon
          title={"Eintrag neu / ändern"}
          hideHome
          backFnc={onClose}
          enableBack
        />
      }
    >
      <div className="text">
        <p>
          Hätten Sie gerne einen neuen Eintrag? Möchten Sie einen Eintrag ändern
          oder löschen lassen? Haben Sie eine neue Telefonnummer oder evtl.
          einen Fehler entdeckt? Haben Sie Anregungen oder Wünsche?
        </p>

        <p>
          Bitte teilen Sie uns Ihre Anliegen unter{" "}
          <a href="mailto:info@lechbank.com">info@lechbank.com</a> mit. Gerne
          versuchen wir, Sie beim nächsten Update dieser App zu berücksichtigen.
        </p>
      </div>
      {/* <IonItem>
        Wenn Sie ihren vorhandenen Eintrag anpassen wollen, bitte klicken sie
        hier:
        <a href="mailto:info@lechbank.com">
          <IonButton size="default" expand="block" fill="solid">
            Ändern
          </IonButton>
        </a>
      </IonItem> */}
    </SplitScreen>
  );
};

export default CreatePhoneBookEntryModal;
