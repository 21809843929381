import IFormDiaryInput from "../models/IFormDiaryInput";
import IFormLoginInput from "../models/IFormLoginInput";
import IFormInputs from "../models/IFormRepoInput";
import Axios from "./Axios";

// Repository
const getRepoCategories = async () => {
  return await Axios.get(
    `repository-categories?populate=icon&populate=repositoryEntries&sort=sort`,
    {}
  );
};

const getRepoCategory = async (id: string) => {
  return await Axios.get(`repository-categories/${id}`, {});
};

const getAllRepoEntries = async () => {
  return await Axios.get(
    `repository-entries?sort=createdAt:desc&populate=image&populate=category`,
    {}
  );
};

const getFilteredRepoEntries = async (q: string) => {
  return await Axios.get(
    `repository-entries?sort=createdAt:desc&populate[0]=category&populate=image&filters[category][id]=${q}`,
    {}
  );
};

const getRepoEntry = async (id: string) => {
  return await Axios.get(
    `repository-entries/${id}?populate=image&populate=category`,
    {}
  );
};

const createNewRepoEntry = async (data: IFormInputs) => {
  return await Axios.post("repository-entries", { data: data });
};

// Event-Calendar
const getDiaryCategories = async (terminOrt: any) => {
  return await Axios.get(
    `event-calendar-categories?populate[eventCalendarEntries][filters][terminOrt][$eq][0]=BEIDES&populate[eventCalendarEntries][filters][terminOrt][$eq][1]=${terminOrt}&populate=icon&sort=sort`,
    {}
  );
};

const getDiaryCategory = async (id: string) => {
  return await Axios.get(`event-calendar-categories/${id}`, {});
};

const getAllDiaryEntries = async (terminOrt: any) => {
  return await Axios.get(
    `event-calendar-entries?populate=image,category&filters[terminOrt][$eq][0]=BEIDES&filters[terminOrt][$eq][1]=${terminOrt}&sort[0]=date&sort[1]=time`,
    {}
  );
};

const getFilteredDiaryEntries = async (q: string, terminOrt: any) => {
  return await Axios.get(
    `event-calendar-entries?populate=category,image&filters[category][id]=${q}&filters[terminOrt][$eq][0]=BEIDES&filters[terminOrt][$eq][1]=${terminOrt}&sort[0]=date&sort[1]=time`,
    {}
  );
};

const getDiaryEntry = async (id: string) => {
  return await Axios.get(
    `event-calendar-entries/${id}?populate=image&populate=category`,
    {}
  );
};

const createNewDiaryEntry = async (data: IFormDiaryInput) => {
  return await Axios.post(`event-calendar-entries`, { data: data });
};

// Phonebook
const getPhoneBookCategories = async () => {
  return await Axios.get(
    `phone-book-categories?populate[phoneBookEntries][populate][0]=phone_book_entries,premiumImage&populate=icon`,
    {}
  );
};

const getPhoneBookEntries = async () => {
  return await Axios.get(
    `phone-book-entries?populate=categories&populate=phone_book_entries&populate=premiumImage`
  );
};

const getPhoneBookCategory = async (id: number) => {
  return await Axios.get(`phone-book-categories/${id}`, {});
};

const getPhoneBookEntriesForCategory = (categoryId: number) => {
  return Axios.get(
    `phone-book-entries?populate=category&filters[category][id][$eq]=${categoryId}`
  );
};

const getPhoneBookEntry = async (id: any) => {
  let res = await Axios.get(
    `phone-book-entries/${id}?populate=premiumImage&populate=phone_book_entries`,
    {}
  );
  return res;
};

const createPhoneBookEntry = (data: any) => {
  return Axios.post("phone-book-entries", { data });
};

// Home
const getSliderContent = async () => {
  return await Axios.get(
    `advertisings?sort=importance:desc&populate=image`,
    {}
  );
};

// Info
const getInfoContent = async () => {
  return await Axios.get(`links?populate=icon&sort=sort`, {});
};

// Login

const getClubs = async () => {
  return await Axios.get(`clubs?populate=rubrik`, {});
};

const login = async (data: IFormLoginInput) => {
  return await Axios.post(`logins`, data);
};

// MY-Diary

const getMyFilteredDiaries = async (arr: []) => {
  return await Axios.get(
    `event-calendar-entries?${arr}&populate=image,category&sort=date`,
    {}
  );
};

// Image-Upload
const uploadImage = async (files: any) => {
  return await Axios.post(`upload`, files);
};

const getImageForEntry = async (filename: any) => {
  const response = await Axios.get(
    `upload/files?filters[name][$eq]=${filename}&fields[0]=url`
  );

  if (response.status === 200) {
    return response?.data[0]?.url;
  }
};

export {
  getImageForEntry,
  uploadImage,
  getRepoCategories,
  getRepoCategory,
  getAllRepoEntries,
  getRepoEntry,
  createNewRepoEntry,
  getFilteredRepoEntries,
  getDiaryCategory,
  getDiaryCategories,
  createNewDiaryEntry,
  getDiaryEntry,
  getFilteredDiaryEntries,
  getAllDiaryEntries,
  getPhoneBookCategories,
  getSliderContent,
  getInfoContent,
  getClubs,
  login,
  getMyFilteredDiaries,
  getPhoneBookEntry,
  getPhoneBookEntriesForCategory,
  getPhoneBookCategory,
  createPhoneBookEntry,
  getPhoneBookEntries,
};
