import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import IPhoneBookCategoryDto from "../models/IPhoneBookCategory.dto";
import {
  getPhoneBookCategories,
  createPhoneBookEntry,
  getPhoneBookEntries,
  getPhoneBookEntry,
  getImageForEntry,
} from "../services/Http.service";

import { Storage } from "@capacitor/storage";
import moment from "moment";

class PhoneBookStore {
  categories: IPhoneBookCategoryDto[] = [];
  selectedCategory: any;
  phoneBookEntriesForCategory: any[] = [];
  phoneBookSearchEntries: any = [];
  selectedPhoneBookEntry: any = {};
  childEntry: any = {};
  query = "";
  locationQuery = "all";
  phoneBookEntries: any[] = [];
  imgURL: string = "";
  childImgURL: string = "";

  constructor() {
    makeObservable(this, {
      imgURL: observable,
      childImgURL: observable,
      query: observable,
      phoneBookEntries: observable,
      locationQuery: observable,
      categories: observable,
      childEntry: observable,
      selectedPhoneBookEntry: observable,
      selectedCategory: observable,
      phoneBookEntriesForCategory: observable,
      phoneBookSearchEntries: observable,
      loadCategories: action,
      loadPhoneBookEntries: action,
      getPhoneBookEntry: action,
      getCategory: action,
      loadPhoneBookForCategory: action,
      getImg: action,
      filteredEntries: computed,
      searchResults: computed,
      searchResultsForCategory: computed,
      filteredCategoryEntries: computed,
      getChildEntry: action,
      getImgChild: action,
    });

    this.load();
  }

  load = async () => {
    let data: any;
    try {
      data = await Storage.get({
        key: "phoneBook",
      });
    } catch (error) {
      console.log(error);
    }

    if (data && data.value) {
      runInAction(() => {
        this.categories = JSON.parse(data.value as string);
      });
    }
  };

  loadPhoneBookEntries = async () => {
    let tmp: any[] = [];
    const response = await getPhoneBookEntries();
    if (response.status === 200) {
      tmp = response.data.data;
    }

    try {
      await this.setStorage(JSON.stringify(tmp), "phoneBookEntries");
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.phoneBookEntries = tmp;
    });
  };

  get searchResults() {
    let results: any[] = [];
    if (!this.phoneBookEntries) {
      return [];
    }

    if (this.query === "") {
      return [];
    }

    for (const entry of this.phoneBookEntries) {
      if (!entry.attributes.suche) {
        continue;
      }

      const searchText = this.query.toLowerCase();
      const reA = /ä/g;
      const reO = /ö/g;
      const reU = /ü/g;

      const entryObj = {
        title: entry.attributes.title,
        name: entry.attributes.name,
        responsiblePerson: entry.attributes.responsiblePerson,
        phoneNumber: entry.attributes.phoneNumber?.replace(/ /g, ""),
        mobileNumber: entry.attributes.mobileNumber?.replace(/ /g, ""),
        fax: entry.attributes.fax?.replace(/ /g, ""),
        postfix: entry.attributes.postfix,
        location: entry.attributes.location,
      };

      const str = JSON.stringify(entryObj).toLowerCase();
      const nStr = str.replace(reA, "a").replace(reU, "u").replace(reO, "o");

      const endStr = searchText.match(/ä|ö|ü/g) ? str : nStr;

      if (
        (entry.attributes.location &&
          entry.attributes.location.toLowerCase() === this.locationQuery) ||
        this.locationQuery === "all"
      ) {
        if (endStr.includes(searchText)) {
          results.push(entry);
        }
      }
    }

    // sort by name
    results.sort((a, b) => {
      const nameA = a.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      const nameB = b.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // name must be equal
      return 0;
    });
    return results;
  }

  get searchResultsForCategory() {
    let results: any[] = [];
    let category = this.selectedCategory;

    if (!this.phoneBookEntries) {
      return [];
    }

    if (this.query === "") {
      return [];
    }

    for (const entry of this.phoneBookEntries) {
      if (
        (entry.attributes.location &&
          entry.attributes.location.toLowerCase() === this.locationQuery) ||
        this.locationQuery === "all"
      ) {
        const res = entry?.attributes?.categories?.data?.find(
          (element: any) => element.id === category.id
        );

        if (res === undefined) {
          continue;
        }

        const searchText = this.query.toLowerCase();
        const reA = /ä/g;
        const reO = /ö/g;
        const reU = /ü/g;

        const entryObj = {
          title: entry.attributes.title,
          name: entry.attributes.name,
          responsiblePerson: entry.attributes.responsiblePerson,
          phoneNumber: entry.attributes.phoneNumber,
          mobileNumber: entry.attributes.mobileNumber,
          fax: entry.attributes.fax,
          postfix: entry.attributes.postfix,
          location: entry.attributes.location,
        };

        const str = JSON.stringify(entryObj).toLowerCase();
        const nStr = str.replace(reA, "a").replace(reU, "u").replace(reO, "o");

        const endStr = searchText.match(/ä|ö|ü/g) ? str : nStr;

        if (endStr.includes(searchText)) {
          results.push(entry);
        }
      }
    }

    // sort by name
    results.sort((a, b) => {
      const nameA = a.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      const nameB = b.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // name must be equal
      return 0;
    });
    return results;
  }

  async setStorage(data: string, key: string) {
    try {
      await Storage.set({
        key: key,
        value: data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async loadCategories(force = false) {
    if (this.categories && this.categories.length > 0 && !force) {
      return;
    }

    const tmp: any = [];
    const response = await getPhoneBookCategories();
    if (response.status === 200) {
      for (let i = 0; i < response.data.data.length; i++) {
        response.data.data[i].numberOfEntries =
          response.data.data[i].attributes.phoneBookEntries.data.length;

        tmp.push(response.data.data[i]);
      }
    }

    try {
      await this.setStorage(JSON.stringify(tmp), "phoneBook");
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.categories = tmp;
    });
  }

  async getChildEntry(id: any) {
    let tmp: any;
    tmp = this.selectedPhoneBookEntry.attributes.phone_book_entries.data.find(
      (child: any) => child.id.toString() === id.toString()
    );
    runInAction(() => {
      this.childEntry = tmp;
    });

    let entry = this.childEntry.attributes;
    let image: any = [];
    let month = new Date().getMonth() + 1;

    if (month >= 5 && month <= 10) {
      entry.imageSummer
        ? (image = await getImageForEntry(entry.imageSummer))
        : entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    } else {
      entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    }

    runInAction(() => {
      this.childImgURL = image;
    });
  }

  get filteredEntries() {
    if (!this.categories) {
      return [];
    }

    for (let category of this.categories) {
      const found: any = [];
      const phonebookEntries = category.attributes.phoneBookEntries;
      for (const phoneEntry of phonebookEntries.data) {
        if (
          (phoneEntry.attributes.location &&
            phoneEntry.attributes.location.toLowerCase() ===
              this.locationQuery) ||
          this.locationQuery === "all"
        ) {
          if (
            category.attributes.name === "Restaurants" ||
            category.attributes.name === "Bars"
          ) {
            found.push(phoneEntry);
          } else {
            if (!phoneEntry.attributes.parentId) {
              found.push(phoneEntry);
            }
          }
        }
      }
      category.numberOfEntries = found.length;
    }
    return this.categories;
  }

  //filter subgenres
  get filteredCategoryEntries() {
    const found = this.phoneBookEntriesForCategory.filter((entry: any) => {
      if (
        (entry.attributes &&
          entry.attributes.location &&
          entry.attributes.location.toLowerCase() === this.locationQuery) ||
        this.locationQuery === "all"
      ) {
        return true;
        // if (
        //   JSON.stringify(entry).toLowerCase().includes(this.query.toLowerCase())
        // ) {
        //   return true;
        // }
      }
      return false;
    });

    // sort by name
    return found.sort((a, b) => {
      const nameA = a.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      const nameB = b.attributes.name
        .toLowerCase()
        .replace("ä", "a")
        .replace("ü", "u")
        .replace("ö", "o"); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // name must be equal
      return 0;
    });
  }

  setQ(q: string) {
    if (q !== " ") {
      runInAction(() => {
        this.query = q;
      });
    }
  }

  loadPhoneBookForCategory = (id: number) => {
    // const response = await getPhoneBookEntriesForCategory(id);
    // if (response.status === 200) {
    //   this.phoneBookEntriesForCategory = response.data.data;
    // }
    let tmp: any;
    tmp = this.categories.filter((category) => category.id === id);
    if (tmp && tmp[0]) {
      runInAction(() => {
        this.phoneBookEntriesForCategory =
          tmp[0].attributes.phoneBookEntries.data;
      });
    }
  };

  async getPhoneBookEntry(id: string) {
    // let tmp: any;
    // for (let category of this.categories) {
    //   tmp = category.attributes.phoneBookEntries.data.find((entry: any) => {
    //     return entry.id.toString() === id;
    //   });
    //   if (tmp) {
    //     tmp.attributes.phone_book_entries.data.sort((a: any, b: any) =>
    //       a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase()
    //         ? 1
    //         : b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase()
    //         ? -1
    //         : 0
    //     );
    //     runInAction(() => {
    //       this.selectedPhoneBookEntry = tmp;
    //     });
    //   }
    // }

    const response = await getPhoneBookEntry(id);
    if (response.status === 200) {
      runInAction(() => {
        this.selectedPhoneBookEntry = response.data.data;
      });

      let entry = response?.data?.data?.attributes;
      let image: any = "";
      let month = new Date().getMonth() + 1;

      if (month >= 5 && month <= 10) {
        entry.imageSummer
          ? (image = await getImageForEntry(entry.imageSummer))
          : entry.imageWinter
          ? (image = await getImageForEntry(entry.imageWinter))
          : (image = "");
      } else {
        entry.imageWinter
          ? (image = await getImageForEntry(entry.imageWinter))
          : (image = "");
      }

      runInAction(() => {
        this.imgURL = image;
      });
    }
  }

  async getImg(id: any) {
    const res = this.phoneBookEntries.find(
      (element: any) => element.id.toString() === id
    );

    const entry = res.attributes;
    let image: any = "";
    let month = new Date().getMonth() + 1;

    if (month >= 5 && month <= 10) {
      entry.imageSummer
        ? (image = await getImageForEntry(entry.imageSummer))
        : entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    } else {
      entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    }

    runInAction(() => {
      this.imgURL = image;
    });
  }

  async getImgChild(parentId: any, childId: any) {
    const parent = this.phoneBookEntries.find(
      (element: any) => element.id.toString() === parentId
    );

    const child = parent?.attributes?.phone_book_entries?.data.find(
      (child: any) => child.id.toString() === childId.toString()
    );

    const entry = child?.attributes;
    let image: any = "";
    let month = new Date().getMonth() + 1;

    if (month >= 5 && month <= 10) {
      entry.imageSummer
        ? (image = await getImageForEntry(entry.imageSummer))
        : entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    } else {
      entry.imageWinter
        ? (image = await getImageForEntry(entry.imageWinter))
        : (image = "");
    }

    runInAction(() => {
      this.childImgURL = image;
    });
  }

  getCategory(id: number) {
    // const response = await getPhoneBookCategory(id);
    // if (response.status === 200) {
    //   this.selectedCategory = response.data.data;
    // }
    this.selectedCategory = this.categories.find(
      (category) => category.id === id
    );
  }

  setLocationQuery(query: string) {
    runInAction(() => (this.locationQuery = query));
  }

  async createPhoneBookEntry(data: any) {
    const res = await createPhoneBookEntry(data);
  }
}

const phoneBookStore = new PhoneBookStore();

export default phoneBookStore;
