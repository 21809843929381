import { observable, action, makeObservable } from "mobx";
import PublicTime from "../models/PublicTime.enum";
import IRepoCategoryDto from "../models/IRepoCategory.dto";
import { IRepoEntryModel } from "../models/IRepoEntry.model";
import IFormRepoInput from "../models/IFormRepoInput";
import {
  createNewRepoEntry,
  getRepoCategories,
  getAllRepoEntries,
  getRepoEntry,
  getFilteredRepoEntries,
  getRepoCategory,
} from "../services/Http.service";

class RepositoryStore {
  categories: IRepoCategoryDto[] = [];
  category: IRepoCategoryDto | undefined;
  entries: IRepoEntryModel[] = [];
  entry: IRepoEntryModel = {
    id: 0,
    attributes: {
      image: {},
      title: "",
      description: "string",
      price: null,
      firstName: "",
      lastName: "",
      email: "",
      category: null,
      agb: false,
      phoneNumber: null,
      company: null,
      isPublicTime: PublicTime.WEEKS8,
      createdAt: null,
      updatedAt: null,
      publishedAt: null,
    },
  };

  constructor() {
    makeObservable(this, {
      categories: observable,
      category: observable,
      entries: observable,
      entry: observable,
      loadCategories: action,
      loadCategory: action,
      getFilteredRepoEntries: action,
      getAllRepoEntries: action,
      getRepoEntry: action,
      createNewRepoEntry: action,
    });
  }

  async loadCategories() {
    const tmp = [];
    const response = await getRepoCategories();
    if (response.status === 200) {
      //find better and dynamic solution
      for (let i = 0; i < response.data.data.length; i++) {
        if (response.data.data[i].attributes.repositoryEntries) {
          response.data.data[i].numberOfEntries =
            response.data.data[i].attributes.repositoryEntries.data.length;
          tmp.push(response.data.data[i]);
        }
      }
    }
    this.categories = tmp;
  }

  async loadCategory(id: string) {
    const response = await getRepoCategory(id);
    if (response.status === 200) {
      this.category = response.data.data;
    }
  }

  async getFilteredRepoEntries(categoryId: string) {
    const response = await getFilteredRepoEntries(categoryId);
    if (response.status === 200) {
      this.entries = response.data.data;
    }
    return response.data.data.length;
  }

  async getAllRepoEntries() {
    const response = await getAllRepoEntries();
    if (response.status === 200) {
      this.entries = response.data.data;
    }
  }

  async getRepoEntry(id: string) {
    const response = await getRepoEntry(id);
    if (response.status === 200) {
      this.entry = response.data.data;
    }
  }

  async createNewRepoEntry(data: IFormRepoInput) {
    return await createNewRepoEntry(data);
  }
}

const repositoryStore = new RepositoryStore();

export default repositoryStore;
