import { IonContent } from "@ionic/react";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router";
import SafeView from "../../components/SafeView/SafeView";
import Toolbar from "../../components/Toolbar/Toolbar";
import rules from "../../data/rules";

const RepoRulesScreen = () => {
  const history = useHistory();
  return (
    <SafeView>
      <IonContent>
        <Toolbar
          title={"Wichtiges"}
          enableBack
          backFnc={() => {
            history.goBack();
          }}
        />

        <p style={{ margin: " 10px" }}>
          <ReactMarkdown>{rules.FUNDGRUBE_REGELN}</ReactMarkdown>
        </p>
      </IonContent>
    </SafeView>
  );
};

export default RepoRulesScreen;
