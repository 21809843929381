import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonSpinner,
  IonTitle,
} from "@ionic/react";
import { useEffect, useState } from "react";
import RepoList from "../../components/RepoList/RepoList";
import "./RepoContentScreen.css";
import { observer } from "mobx-react";

import repositoryStore from "../../stores/repository.store";
import { useParams } from "react-router-dom";

import SafeView from "../../components/SafeView/SafeView";
import Toolbar from "../../components/Toolbar/Toolbar";
import SplitScreen from "../../components/SplitScreen/SplitScreen";

const RepoContentScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const categoryId = useParams<{ id: string }>().id;
  let category = repositoryStore.category;

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    if (categoryId === "1") {
      await repositoryStore.getAllRepoEntries();
    } else {
      await repositoryStore.getFilteredRepoEntries(categoryId);
    }
    await repositoryStore.loadCategory(categoryId);
    setLoading(false);
  }

  return (
    <SafeView className="repo-content-page">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <IonSpinner />
        </div>
      ) : (
        <SplitScreen
          header={
            <Toolbar
              title={category ? category.attributes.name : ""}
              backUrl="/fundgrube"
              enableBack
            />
          }
        >
          <RepoList />
        </SplitScreen>
      )}
    </SafeView>
  );
};

export default observer(RepoContentScreen);
