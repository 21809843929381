import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  RefresherEventDetail,
} from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import AddNewDiaryEntryButton from "../../components/AddNewDiaryEntryButton/AddNewDiaryEntryButton";
import DiaryChip from "../../components/DiaryChip/DiaryChip";
import DiaryEntryForm from "../../components/DiaryEntryForm/DiaryEntryForm";
import SafeView from "../../components/SafeView/SafeView";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import Toolbar from "../../components/Toolbar/Toolbar";
import diaryStore from "../../stores/diary.store";
import loginStore from "../../stores/login.store";
import myDiariesStore from "../../stores/my-diaries.store";
import LoginModalScreen from "../LoginModalScreen/LoginModalScreen";
import "./DiaryScreen.css";

const DiaryScreen: React.FC = () => {
  const [logedIn, setLogedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpenLog, setIsOpenLog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const locationQuery = diaryStore.locationQuery;
  const diaryIds = myDiariesStore.diaryIds;
  const categories = diaryStore.categories;

  useEffect(() => {
    loadData();
  }, [locationQuery]);

  async function loadData() {
    await loginStore.loadClubs();
    await diaryStore.loadCategories();
    setLoading(false);
  }

  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    await loginStore.loadClubs();
    await diaryStore.loadCategories();
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  }

  return (
    <SafeView className="diary-page">
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <SplitScreen
          header={
            <Toolbar
              icon
              title="Termine"
              button={
                <AddNewDiaryEntryButton
                  onClick={() => {
                    setIsOpenLog(true);
                  }}
                />
              }
            />
          }
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <IonSpinner />
            </div>
          ) : (
            <>
              <div>
                <IonSegment
                  color={"#c8c8c8"}
                  className="phonebook-segment"
                  value={locationQuery}
                  onIonChange={(e: any) => {
                    if (e.detail.value === "LECH") {
                      diaryStore.setLocationQuery("LECH");
                    }

                    diaryStore.setLocationQuery(
                      e.detail.value ? e.detail.value.toUpperCase() : ""
                    );
                  }}
                >
                  <IonSegmentButton
                    value="LECH"
                    className="phonebook-segment-button"
                  >
                    <IonLabel>Lech Zürs</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="WARTH"
                    className="phonebook-segment-button"
                  >
                    <IonLabel>Warth Schröcken</IonLabel>
                  </IonSegmentButton>
                </IonSegment>

                <IonList>
                  {diaryStore.categories.map((category) => {
                    return <DiaryChip key={category.id} category={category} />;
                  })}
                </IonList>
              </div>
              <div>
                <div className="phonebook-title">Infos</div>
              </div>
              <IonItem
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsOpenLog(true);
                }}
                lines="full"
              >
                <IonLabel>Neuen Eintrag erstellen</IonLabel>
              </IonItem>

              <IonModal
                isOpen={isOpenLog}
                onDidDismiss={() => {
                  setIsOpenLog(false);
                }}
              >
                <LoginModalScreen
                  dismissModal={() => {
                    setIsOpenLog(false);
                  }}
                  success={() => {
                    setLogedIn(true);
                    setIsOpenLog(false);
                    setIsOpen(true);
                  }}
                />
              </IonModal>

              <IonModal
                isOpen={isOpen}
                onDidDismiss={() => {
                  setIsOpen(false);
                }}
              >
                {logedIn && (
                  <DiaryEntryForm
                    dismissModal={() => {
                      setIsOpen(false);
                      setLogedIn(false);
                    }}
                  />
                )}
              </IonModal>
            </>
          )}
        </SplitScreen>
      </IonContent>
    </SafeView>
  );
};

export default observer(DiaryScreen);
