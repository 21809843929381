import { action, makeObservable, observable } from "mobx";
import IFormLoginInput from "../models/IFormLoginInput";
import { getClubs, login } from "../services/Http.service";

class LoginStore {
  clubs = [
    {
      attributes: { name: "", rubrik: { data: { id: -1, attributes: {} } } },
      id: -1,
    },
  ];
  selectedCategory: any = {};
  credentials: IFormLoginInput = {
    club: undefined,
    password: "",
  };

  constructor() {
    makeObservable(this, {
      clubs: observable,
      selectedCategory: observable,
      credentials: observable,
      loadClubs: action,
      sendLoginData: action,
    });
  }

  loadClubs = async () => {
    const response = await getClubs();
    if (response.status === 200) {
      this.clubs = response.data.data;
    }
  };

  sendLoginData = async (data: IFormLoginInput) => {
    const response = await login(data);
    if (response.status === 200) {
      this.credentials = data;
    }
    return response;
  };
}

const loginStore = new LoginStore();

export default loginStore;
