import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonActionSheet,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import IFormDiaryInput from "../../models/IFormDiaryInput";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import diaryStore from "../../stores/diary.store";
import loginStore from "../../stores/login.store";
import IDiaryCategoryDto from "../../models/IDiaryCategory.dto";
import { showTabBar } from "../../helpers/tabBarHandler";

import "./DiaryEntryForm.css";
import moment from "moment";
import Toolbar from "../Toolbar/Toolbar";
import SafeView from "../SafeView/SafeView";
import SplitScreen from "../SplitScreen/SplitScreen";
import { uploadImage } from "../../services/Http.service";

const options = {
  cssClass: "my-custom-interface",
};

const MAX_IMAGE_SIZE = 10 * 1024 * 1024;

const DiaryEntryForm = ({ dismissModal }: any) => {
  const today = new Date(Date.now());
  const [selectedDate, setSelectedDate] = useState<Date | string>(today);
  const [selectedTime, setSelectedTime] = useState<Date | string>("12:00");
  const [images, setImages] = useState<any>([]);
  const [presentAlert] = useIonAlert();
  const [present] = useIonActionSheet();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const selectedCategory =
    loginStore.selectedCategory?.attributes?.rubrik?.data?.id;
  const categories = diaryStore.categories.filter(
    (c) => c.attributes.name !== "Meine Termine"
  );

  const selectedClub = loginStore.selectedCategory;
  async function handleButtonClick(message: string) {
    presentAlert({
      subHeader: "Info",
      message: `<h6 style={{fontSize: "30px"}} >${message}</h6>`,
      buttons: ["OK"],
    });
  }

  function canDismiss() {
    return new Promise<boolean>((resolve, reject) => {
      present({
        cssClass: "ja-nein-sheet",
        header: "Möchten Sie den Vorgang wirklich abbrechen?",
        buttons: [
          {
            text: "Ja",
            role: "confirm",
          },
          {
            text: "Nein",
            role: "cancel",
          },
        ],
        onWillDismiss: (ev) => {
          if (ev.detail.role === "confirm") {
            resolve(dismissModal());
          } else {
            reject();
          }
        },
      });
    });
  }

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState,
    formState: { isSubmitSuccessful, errors },
  } = useForm<IFormDiaryInput>({
    defaultValues: {
      title: "",
      date: "",
      time: "",
      location: "",
      description: "",
      image: null,
      organizerNumber: selectedClub.attributes?.telefon
        ? selectedClub.attributes?.telefon
        : "",
      organizerEmail: selectedClub.attributes?.email
        ? selectedClub.attributes?.email
        : "",
      organizerName: selectedClub.attributes?.name
        ? selectedClub.attributes?.name
        : "",
      organizerContact: selectedClub.attributes?.ansprechperson
        ? selectedClub.attributes?.ansprechperson
        : "",
      organizerWeb: selectedClub.attributes?.web
        ? removeHttp(selectedClub.attributes?.web)
        : "",
      organizerAddress: selectedClub.attributes?.adresse
        ? selectedClub.attributes?.adresse
        : "",
      createdAt: null,
      category: selectedCategory,
      updatedAt: null,
      publishedAt: null,
    },
  });

  const formSubmitHandler: SubmitHandler<IFormDiaryInput> = async (
    data: IFormDiaryInput
  ) => {
    data.publishedAt = new Date();
    let strapiTimeFormat = selectedTime + ":00";
    data.time = strapiTimeFormat as unknown as Date;
    data.date = selectedDate;

    let response;
    try {
      response = await diaryStore.createNewDiaryEntry({
        ...data,
        image: images.map((item: any) => item.id),
      });
      handleButtonClick("Termin wurde erstellt");
      dismissModal();
    } catch (error: any) {
      handleButtonClick("Ein Fehler ist aufgetreten!");
      canDismiss();
    }
  };

  function removeHttp(url: any) {
    if (url.startsWith("https://")) {
      const https = "https://";
      return url.slice(https.length);
    }

    if (url.startsWith("http://")) {
      const http = "http://";
      return url.slice(http.length);
    }

    return url;
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <SplitScreen
      style="modal"
      header={
        <Toolbar
          className={isPlatform("ios") ? "ios-top-spacing" : ""}
          title="Termin erstellen"
          enableBack
          backFnc={() => {
            canDismiss();
          }}
        />
      }
    >
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        {/* title-bar */}

        {/* image
            <IonRow>
              <IonCol>
                <IonItem>
                  <input {...register("image")} type="file" name="image" />
                </IonItem>
              </IonCol>
            </IonRow> */}

        {/* ! ! ! kategorie ! ! !  */}
        <div>
          <IonItem>
            <IonLabel position="stacked">Kategorie</IonLabel>
            <Controller
              render={({ field }) => (
                <IonSelect
                  disabled
                  interfaceOptions={options}
                  placeholder="Kategorie*"
                  value={field.value}
                  onIonChange={(e) => {
                    setValue("category", e.detail.value);
                  }}
                >
                  {categories
                    .filter((e) => e.id > 1)
                    .map((category: IDiaryCategoryDto) => {
                      const { attributes } = category;

                      return (
                        <IonSelectOption value={category.id} key={category.id}>
                          {attributes.name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              )}
              control={control}
              name="category"
              rules={{ required: "Kategorie darf nicht leer sein" }}
            />
          </IonItem>
          <ErrorMessage
            name="category"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />
          {/* title */}
          <IonItem>
            <IonInput
              {...register("title", {
                required: "Geben Sie einen Titel ein",
                minLength: { value: 3, message: "min. 3 Zeichen " },
              })}
              type="text"
              name="title"
              placeholder="Titel*"
            ></IonInput>
          </IonItem>
          <ErrorMessage
            name="title"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />
          {/* description */}
          <IonItem>
            <IonTextarea
              {...register("description", {
                required: "Geben Sie eine Beschreibung ein",
                minLength: { value: 3, message: "min. 3 Zeichen " },
              })}
              name="description"
              placeholder="Beschreibung*"
            ></IonTextarea>
          </IonItem>
          <ErrorMessage
            name="description"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />
          {/* location */}
          <IonItem>
            <IonInput
              {...register("location", {
                required: "Geben Sie einen Ort ein",
                minLength: { value: 2, message: "min. 2 Zeichen " },
              })}
              type="text"
              name="location"
              placeholder="Veranstaltungsort*"
            ></IonInput>
          </IonItem>
          <ErrorMessage
            name="location"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />
          {/* ! ! ! mögliche locations auswahl ! ! ! */}
          {/* locations  */}
          {/* <IonRow>
            <IonCol>
              <IonSegment
                value={getValues("isPublicTime")}
                {...register("isPublicTime", { required: true })}
                onIonChange={(e) =>
                  setValue("isPublicTime", e.detail.value as any)
                }
              >
                <IonSegmentButton value="WEEKS2">
                  <IonLabel>2 Wochen</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="WEEKS4">
                  <IonLabel>4 Wochen</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="WEEKS8">
                  <IonLabel>8 Wochen</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow> */}
          {/* date */}
          <IonItem>
            <IonInput
              id="date"
              {...register("date", {
                required: "Wählen Sie ein Datum",
              })}
              type="text"
              name="date"
              placeholder="Datum*"
              value={moment(selectedDate).format("DD.MM.YYYY")}
            ></IonInput>
            <IonModal trigger="date" id="diary-modal">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h5
                    style={{
                      paddingLeft: 15,
                      fontWeight: "bold",
                      marginBottom: 15,
                    }}
                  >
                    Datum wählen
                  </h5>
                  <IonDatetime
                    color="dark"
                    onIonChange={(e) => {
                      setSelectedDate(moment(e.detail.value).toDate());
                    }}
                    showDefaultButtons
                    doneText="Fertig"
                    cancelText="Abbrechen"
                    presentation="date"
                    min={moment(selectedDate).format("YYYY-MM-DD")}
                  ></IonDatetime>
                </div>
              </div>
            </IonModal>
          </IonItem>
          <ErrorMessage
            name="date"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />
          {/* time */}
          <IonItem style={{ marginBottom: 10 }}>
            <IonInput
              {...register("time", {
                required: "Wählen Sie eine Uhrzeit",
              })}
              type="text"
              name="time"
              placeholder="Uhrzeit*"
              id="time"
              value={selectedTime.toString()}
            ></IonInput>
            <IonModal trigger="time" id="diary-modal">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h5
                    style={{
                      paddingLeft: 25,
                      fontWeight: "bold",
                      marginBottom: 15,
                    }}
                  >
                    Zeit wählen
                  </h5>
                  <IonDatetime
                    color="dark"
                    onIonChange={(e) =>
                      setSelectedTime((prev) =>
                        moment(e.detail.value!).format("HH:mm")
                      )
                    }
                    showDefaultButtons
                    doneText="Fertig"
                    cancelText="Abbrechen"
                    presentation="time"
                  ></IonDatetime>
                </div>
              </div>
            </IonModal>
          </IonItem>
          <ErrorMessage
            name="time"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />
          {/* image */}
          <div>
            <div className="phonebook-title">
              {" "}
              <div>Foto hochladen</div>
              <div className="info-text">Max. Bildgröße: 10MB</div>
            </div>
          </div>
          {images.length === 0 && !loading && (
            <IonItem lines="none" style={{ textAlign: "center" }}>
              Es wurde noch kein Bild hochgeladen
            </IonItem>
          )}
          {images.map((image: any, key: any) => {
            return (
              <IonRow key={key} style={{ paddingTop: 5 }}>
                <IonItem key={key} className="preview" lines="none" mode="md">
                  <div className="center">
                    <img
                      src={image.url}
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 5,
                      }}
                    />
                    {image.name}
                  </div>
                  <div
                    onClick={() => {
                      setImages(
                        images.filter((img: any) => img.name !== image.name)
                      );
                    }}
                    className="delete"
                    slot="end"
                  >
                    <img
                      src="/assets/icon/cross.svg"
                      style={{ width: 32, height: 32 }}
                    />
                  </div>
                </IonItem>
              </IonRow>
            );
          })}
          {loading && (
            <IonItem lines="none">
              <div className="spinner">
                <IonSpinner></IonSpinner>
              </div>
            </IonItem>
          )}
          <IonRow>
            <IonCol>
              <IonButton
                size="default"
                expand="block"
                fill="solid"
                disabled={images.length >= 1 ? true : false}
                className="dropzone-container"
              >
                Bild hochladen
                <input
                  type="file"
                  name="image"
                  className="dropzone"
                  onChange={async (event) => {
                    if (!event || !event.target) {
                      return;
                    }
                    const imgFile = event.target!.files![0];
                    if (imgFile.size > MAX_IMAGE_SIZE) {
                      presentAlert({
                        subHeader: "Max. Bildgröße beachten",
                        message: `${imgFile.name}`,
                        buttons: ["OK"],
                      });
                    } else if (
                      imgFile.type !== "image/jpeg" &&
                      imgFile.type !== "image/png"
                    ) {
                      presentAlert({
                        subHeader: "Nur Bilder erlaubt",
                        message: `${imgFile.name}`,
                        buttons: ["OK"],
                      });
                    } else {
                      var formData = new FormData();
                      formData.append("files", imgFile, imgFile.name);
                      setLoading(true);
                      const imageUpload = await uploadImage(formData);
                      setImages([...images, imageUpload.data[0]]);
                      setLoading(false);
                    }
                    event.target.value = "";
                  }}
                />
              </IonButton>
            </IonCol>
          </IonRow>

          {/* title-bar */}
          <IonToolbar color="light">
            <div className="div-title">Kontakt</div>
          </IonToolbar>

          {/* organizerName */}
          <IonItem>
            <IonInput
              {...register("organizerName", {})}
              type="text"
              name="organizerName"
              placeholder="Organisationsname"
            ></IonInput>
          </IonItem>

          {/* organizerContact */}
          <IonItem>
            <IonInput
              {...register("organizerContact", {})}
              type="text"
              name="organizerContact"
              placeholder="Ansprechperson"
            ></IonInput>
          </IonItem>

          {/* organizerNumber */}
          <IonItem>
            <IonInput
              {...register("organizerNumber", {})}
              type="text"
              name="organizerNumber"
              placeholder="Telefon"
            ></IonInput>
          </IonItem>

          {/* organizerEmail */}
          <IonItem>
            <IonInput
              {...register("organizerEmail", {
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Gib eine gültige Email ein",
                },
              })}
              type="text"
              name="organizerEmail"
              placeholder="Email"
              defaultValue=""
            ></IonInput>
          </IonItem>
          <ErrorMessage
            name="organizerEmail"
            errors={errors}
            as={<div style={{ marginLeft: "15px", color: "red" }} />}
          />

          {/* organizerWeb */}
          <IonItem>
            <IonInput
              {...register("organizerWeb", {})}
              type="text"
              name="organizerWeb"
              placeholder="Web"
            ></IonInput>
          </IonItem>

          {/* organizerAddress */}
          <IonItem>
            <IonInput
              {...register("organizerAddress", {})}
              type="text"
              name="organizerAddress"
              placeholder="Adresse"
            ></IonInput>
          </IonItem>

          {/* confirm-button */}
          <IonRow style={{ padding: 20, paddingTop: 10 }}>
            {/* cancel-button */}
            <IonCol>
              <IonButton
                routerLink="/termine"
                routerDirection="back"
                onClick={() => canDismiss()}
                size="default"
                expand="block"
                fill="outline"
                type="button"
                color="danger"
              >
                Abbrechen
              </IonButton>
            </IonCol>

            <IonCol>
              <IonButton
                size="default"
                expand="block"
                fill="solid"
                type="submit"
              >
                Senden
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
      </form>
    </SplitScreen>
  );
};

export default observer(DiaryEntryForm);
