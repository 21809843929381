import { LocalNotifications } from "@capacitor/local-notifications";
import { useEffect } from "react";
import { useHistory } from "react-router";

const LocalPushContainer = ({ children }: any) => {
  const history = useHistory();

  useEffect(() => {
    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      async (notification: any) => {
        if (!notification?.notification?.extra || Object.keys(notification?.notification?.extra).length === 0) {
          try {
            history.push(`/termine/${notification.notification.id}`);
          } catch (error) {
            console.log(error)
          }
        }
      }
    );
  }, []);

  return <div>{children}</div>;
};

export default LocalPushContainer;
