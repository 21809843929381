import { IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import DiaryEntryForm from "../../components/DiaryEntryForm/DiaryEntryForm";
import { hideTabBar } from "../../helpers/tabBarHandler";

const AddDiaryEntryScreen: React.FC = () => {
  useEffect(() => {
    hideTabBar();
  }, []);

  return (
    <IonPage>
      <DiaryEntryForm />
    </IonPage>
  );
};

export default AddDiaryEntryScreen;
