import { IonContent, IonPage } from "@ionic/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import RepoDetailCard from "../../components/RepoDetailCard/RepoDetailCard";
import repositoryStore from "../../stores/repository.store";
import { observer } from "mobx-react";
import SafeView from "../../components/SafeView/SafeView";
import Toolbar from "../../components/Toolbar/Toolbar";
import SplitScreen from "../../components/SplitScreen/SplitScreen";

const RepoEntryDetailScreen: React.FC = () => {
  const id = useParams<{ id: string }>().id;

  useEffect(() => {
    repositoryStore.getRepoEntry(id);
  }, []);

  return (
    <IonPage>
      <SplitScreen header={<Toolbar icon title="" hideAll />}>
        <RepoDetailCard />
      </SplitScreen>
    </IonPage>
  );
};

export default observer(RepoEntryDetailScreen);
