import { IonIcon, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import diaryStore from "../../stores/diary.store";

import "./DiaryContentCard.css";

const DiaryContentCard: React.FC<{
  index: number;
  settings: any;
  alldiaries: boolean;
}> = ({ index, settings, alldiaries }) => {
  const data = diaryStore.getDiaryEntryFromLocal(index);
  if (!data) {
    return <></>;
  }
  const favorites = settings;
  const date = moment(data.attributes.date);
  const time = data.attributes.time.slice(0, -3);
  const categoryId = data?.attributes?.category?.data?.id;

  const getImage = () => {
    if (data?.attributes?.image?.data?.attributes?.url) {
      return data.attributes.image.data.attributes.url;
    } else if (data?.attributes?.imageURL) {
      return data.attributes.imageURL;
    }

    return `/assets/${categoryId}-termine.jpg`;
  };

  return (
    <IonItem mode="md" lines="full" routerLink={`/termine/${data.id}`}>
      <IonThumbnail className="diary-avatar" slot="start">
        <img src={getImage()}></img>
      </IonThumbnail>
      <div className="diary-title">
        <div className="side-by-side spacing-between">
          <IonLabel className="title" style={{ display: "flex", height: 30 }}>
            <p style={{ marginRight: 10 }}>{date.format("DD.MM.YYYY")}</p>
            {time !== "00:00" && <p>{time} Uhr</p>}
          </IonLabel>

          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {favorites && favorites.favorite && (
              <IonIcon
                style={{ fontSize: "20px" }}
                icon="/assets/icon/icon-34-stern-aktiv.svg"
              ></IonIcon>
            )}
            {(!favorites || !favorites.favorite) && (
              <IonIcon
                style={{ fontSize: "20px" }}
                icon="/assets/icon/icon-33-stern-inaktiv.svg"
              ></IonIcon>
            )}
            {favorites && favorites.notifyMe && (
              <IonIcon
                style={{ fontSize: "20px" }}
                icon="/assets/icon/icon-36-glocke-aktiv.svg"
              ></IonIcon>
            )}
            {(!favorites || !favorites.notifyMe) && (
              <IonIcon
                style={{ fontSize: "20px" }}
                icon="/assets/icon/icon-35-glocke-inaktiv-black.svg"
              ></IonIcon>
            )}
          </div>
        </div>
        <IonLabel className="title">{data.attributes.title}</IonLabel>
        <IonLabel className="title">
          <p>{data.attributes.location}</p>
        </IonLabel>
        <IonLabel className="title">
          <p>
            {alldiaries ? "Kategorie:" : null}{" "}
            {data.attributes.category?.data?.attributes.name}
          </p>
        </IonLabel>
      </div>
    </IonItem>
  );
};

export default observer(DiaryContentCard);
