import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonTitle,
  IonList,
  IonSpinner,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DiaryContentCard from "../../components/DiaryContentCard/DiaryContentCard";
import SafeView from "../../components/SafeView/SafeView";
import SplitScreen from "../../components/SplitScreen/SplitScreen";
import Toolbar from "../../components/Toolbar/Toolbar";
import { IDiaryEntryModel } from "../../models/IDiaryEntry.model";
import diaryStore from "../../stores/diary.store";
import myDiariesStore from "../../stores/my-diaries.store";
import "./DiaryContentScreen.css";

const DiaryContentScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const categoryId = useParams<{ id: string }>().id;
  let category = diaryStore.category;
  const history = useHistory();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    if (categoryId === "1") {
      await diaryStore.getAllDiaryEntries();
    } else {
      await diaryStore.getFilteredDiaryEntries(categoryId);
    }
    await diaryStore.loadCategory(categoryId);
    setLoading(false);
  }

  return (
    <SafeView className="diary-content-page">
      <SplitScreen
        header={
          <Toolbar
            title={category?.attributes ? category?.attributes.name : ""}
            enableBack
            backUrl="/termine"
            backFnc={() => {
              history.goBack();
            }}
          />
        }
      >
        {loading ? (
         <div
         style={{
           display: "flex",
           justifyContent: "center",
           marginTop: "20px",
         }}
       >
         <IonSpinner />
       </div>
        ) : (
          <IonList>
            {diaryStore.entries.length > 0 ? (
              diaryStore.entries.map((entry: IDiaryEntryModel, key: number) => {
                return (
                  <DiaryContentCard
                    key={entry.id}
                    index={entry.id}
                    settings={myDiariesStore.getMySettingsOfDiary(entry.id)}
                    alldiaries={categoryId === "1" ? true : false}
                  />
                );
              })
            ) : (
              <div className="nores">Keine Einträge vorhanden</div>
            )}
          </IonList>
        )}
      </SplitScreen>
    </SafeView>
  );
};

export default observer(DiaryContentScreen);
