import { IonButton, IonIcon, isPlatform } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router-dom";

//style

import "./BackButton.css";

const BackButton: React.FC = () => {
  const history = useHistory();

  return (
    <IonButton
      className={isPlatform("ios") ? "btn-ios" : "btn"}
      onClick={() => history.goBack()}
    >
      <IonIcon
        className="icon"
        style={{ color: "white" }}
        icon={chevronBackOutline}
      />
    </IonButton>
  );
};

export default BackButton;
