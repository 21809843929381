import { action, makeObservable, observable, runInAction } from "mobx";
import ISliderContentDto from "../models/ISliderContent.dto";
import { getSliderContent } from "../services/Http.service";

class HomeStore {
  sliderContent: ISliderContentDto[] = [];

  constructor() {
    makeObservable(this, {
      sliderContent: observable,
      loadSliderContent: action,
    });
  }

  async loadSliderContent() {
    const response = await getSliderContent();

    if ((response.status = 200)) {
      runInAction(() => {
        this.sliderContent = response.data.data;
      });
    }
  }
}

const homeStore = new HomeStore();

export default homeStore;
