import { action, computed, makeObservable, observable } from "mobx";
import { Storage } from "@capacitor/storage";
import { getMyFilteredDiaries } from "../services/Http.service";
import { IDiaryEntryModel } from "../models/IDiaryEntry.model";
import getMultipleContent from "../helpers/queryStringHelper";

class MyDiariesStore {
  diaryIds: any = {};
  myDiaries: IDiaryEntryModel[] = [];

  constructor() {
    makeObservable(this, {
      diaryIds: observable,
      myDiaries: observable,
      loadMyDiaries: action,
      getMyDiaries: action,
      removeDiary: action,
      setNotifyMe: action,
      setFavorite: action,
      getMySettingsOfDiary: action,
      numberOfEntries: computed,
    });

    this.getMyDiaries();
  }

  get numberOfEntries() {
    return this.myDiaries.length;
  }

  loadMyDiaries = async (q: any) => {
    const response: any = await getMyFilteredDiaries(q);
    const myDiaryIdKeys = Object.keys(this.diaryIds);
    const foundEntries = response?.data?.data.filter((entry: any) =>
      myDiaryIdKeys.includes(String(entry.id))
    );
    const foundIds = foundEntries.map((entry: any) => {
      return entry.id;
    });

    const notExistingIds = myDiaryIdKeys.filter(
      (key) => !foundIds.includes(parseInt(key))
    );

    for (let i = 0; i < notExistingIds.length; i++) {
      await this.removeDiary(parseInt(notExistingIds[i]));
    }

    if (response.status === 200) {
      this.myDiaries = response.data.data;
    }
    return response.data.data;
  };

  getMyDiaries = async () => {
    if (Object.keys(this.diaryIds).length === 0) {
      try {
        let ids = await Storage.get({ key: "my-diaryIds" });

        if (ids.value !== null) {
          this.diaryIds = await JSON.parse(ids.value);
        } else {
          await Storage.set({
            key: "my-diaryIds",
            value: JSON.stringify({}),
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  async setNotifyMe(value: number, notifyMe: boolean) {
    this.diaryIds[value] = JSON.parse(
      JSON.stringify({ ...this.diaryIds[value], notifyMe })
    );

    try {
      await Storage.set({
        key: "my-diaryIds",
        value: JSON.stringify(this.diaryIds),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async setFavorite(value: number, favorite: boolean, terminOrt: any) {
    this.diaryIds[value] = JSON.parse(
      JSON.stringify({ ...this.diaryIds[value], favorite, terminOrt })
    );

    const ids = this.diaryIds;
    const idArr = Object.entries(ids);
    let content = idArr.filter(([key, value]: any) => value.favorite === true);
    let res = Object.fromEntries(content);
    let favContent = Object.keys(res);

    if (favContent.length > 0) {
      const q = getMultipleContent(favContent);
      await myDiariesStore.loadMyDiaries(q);
    } else {
      this.myDiaries = [];
    }

    try {
      await Storage.set({
        key: "my-diaryIds",
        value: JSON.stringify(this.diaryIds),
      });
    } catch (error) {
      console.log(error);
    }
  }

  getMySettingsOfDiary(value: number) {
    return this.diaryIds[value];
  }

  async removeDiary(value: number) {
    delete this.diaryIds[value];
    try {
      await Storage.set({
        key: "my-diaryIds",
        value: JSON.stringify(this.diaryIds),
      });
    } catch (error) {
      console.log(error);
    }
  }
}
const myDiariesStore = new MyDiariesStore();

export default myDiariesStore;
