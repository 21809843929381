import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import phoneBookStore from "../../stores/phoneBook.store";

interface PhoneBookEntryProps {
  data: any;
}

const PhoneBookEntry = ({ data }: PhoneBookEntryProps) => {
  const { attributes } = data;

  const number = attributes?.phone_book_entries?.data?.length;

  return (
    <IonItem lines="full" routerLink={`/telefonbuch-detail/${data.id}`}>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: "bold" }}>
          {attributes?.name ? attributes.name : ""}
        </div>
        <div style={{ marginLeft: 5 }}>
          {attributes?.nameA ? attributes.nameA : ""}
        </div>
        <div style={{ marginLeft: 5 }}>
          {attributes?.postfix ? attributes.postfix : ""}
        </div>
      </div>

      {number > 0 && (
        <IonBadge slot="end" color="primary">
          {number}
        </IonBadge>
      )}
    </IonItem>
  );
};

export default observer(PhoneBookEntry);
