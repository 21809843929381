import { IonContent, IonList, IonItem, IonSpinner } from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import DiaryContentCard from "../../components/DiaryContentCard/DiaryContentCard";
import SafeView from "../../components/SafeView/SafeView";
import Toolbar from "../../components/Toolbar/Toolbar";
import getMultipleContent from "../../helpers/queryStringHelper";
import { IDiaryEntryModel } from "../../models/IDiaryEntry.model";
import diaryStore from "../../stores/diary.store";
import myDiariesStore from "../../stores/my-diaries.store";

import "./MyDiariesScreen.css";

const MyDiariesScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const loadDiaries = async () => {
    await diaryStore.getAllDiaryEntries();
    await myDiariesStore.getMyDiaries();
    const ids = myDiariesStore.diaryIds;

    const idArr = Object.entries(ids);
    let content = idArr.filter(([key, value]: any) => value.favorite === true);
    let res = Object.fromEntries(content);
    let favContent = Object.keys(res);

    if (favContent.length > 0) {
      const q = getMultipleContent(favContent);
      await myDiariesStore.loadMyDiaries(q);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadDiaries();
  }, []);

  return (
    <SafeView>
      <IonContent>
        <Toolbar title={"Meine Termine"} enableBack backUrl="/termine" />
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" , marginTop:"20px"}}>
            <IonSpinner />
          </div>
        ) : (
          <IonList>
            {myDiariesStore.myDiaries.length > 0 ? (
              myDiariesStore.myDiaries.map(
                (entry: IDiaryEntryModel, key: number) => {
                  return (
                    <div>
                      <DiaryContentCard
                        key={entry.id}
                        index={entry.id}
                        settings={myDiariesStore.getMySettingsOfDiary(entry.id)}
                        alldiaries={false}
                      />
                    </div>
                  );
                }
              )
            ) : (
              <IonItem lines="none">
                <h2>Keine gespeicherten Termine</h2>
              </IonItem>
            )}
          </IonList>
        )}
      </IonContent>
    </SafeView>
  );
};

export default observer(MyDiariesScreen);
