import { IonButton, IonIcon } from "@ionic/react";
import React from "react";

const AddNewDiaryEntryButton = ({ onClick }: any) => {
  return (
    <IonButton onClick={onClick} fill="clear" size="large" slot="end">
      <IonIcon
        className="icon-add"
        icon="/assets/icon/icon-37-neuer-eintrag.svg"
      />
    </IonButton>
  );
};

export default AddNewDiaryEntryButton;
