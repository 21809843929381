import { IonContent, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import SafeView from "../../components/SafeView/SafeView";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import phoneBookStore from "../../stores/phoneBook.store";
import Toolbar from "../../components/Toolbar/Toolbar";
import { observer } from "mobx-react";

interface SearchDetailChildScreen {
  data: any;
}

const SearchDetailChildScreen: React.FC = () => {
  const history = useHistory();
  const { parentId, childId } = useParams<{
    parentId: string;
    childId: string;
  }>();
  let image = phoneBookStore.childImgURL;

  const parent = phoneBookStore?.phoneBookEntries?.find(
    (element: any) => element.id.toString() === parentId
  );

  const child = parent?.attributes?.phone_book_entries?.data.find(
    (child: any) => child.id.toString() === childId.toString()
  );

  const entry = child?.attributes;

  const generateHeaderName = () => {
    if (!entry) {
      return "";
    }
    if (entry.name) {
      return entry.name;
    }
    return "";
  };

  useEffect(() => {
    phoneBookStore.getImgChild(parentId, childId);
  }, [parentId, childId]);

  return (
    <SafeView>
      <IonContent>
        <Toolbar
          title={generateHeaderName()}
          icon={false}
          enableBack
          backFnc={() => {
            history.goBack();
          }}
        />

        {entry?.isPremium && image !== "" ? (
          <div className="container">
            <img className="image" src={image} alt="img" />
          </div>
        ) : null}

        {/* Kontakt */}
        {entry?.responsiblePerson && (
          <IonItem>
            <IonText class="cat-text">Kontakt</IonText>
            <IonLabel>{entry.responsiblePerson}</IonLabel>
            <IonIcon />
          </IonItem>
        )}

        {/* Telefon */}
        {entry?.phoneNumber && entry.phoneNumber !== "null" && (
          <a href={`tel: ${entry.phoneNumber}`} className="link">
            <IonItem>
              <IonText class="cat-text">Telefon</IonText>
              <IonLabel>{entry.phoneNumber}</IonLabel>
              <IonIcon
                slot="end"
                className="icon-add"
                icon="/assets/icon/icon-42-telefon.svg"
              />
            </IonItem>
          </a>
        )}

        {/* Mobil */}
        {entry?.mobileNumber && (
          <a href={`tel: ${entry.mobileNumber}`} className="link">
            <IonItem>
              <IonText class="cat-text">Mobil</IonText>
              <IonLabel>{entry.mobileNumber}</IonLabel>
              <IonIcon
                slot="end"
                className="icon-add"
                icon="/assets/icon/icon-42-telefon.svg"
              />
            </IonItem>
          </a>
        )}

        {/* Fax */}
        {entry?.fax && (
          <IonItem>
            <IonText class="cat-text">Fax</IonText>
            <IonLabel>{entry.fax}</IonLabel>
            <IonIcon />
          </IonItem>
        )}

        {/* Email */}
        {entry?.email && (
          <a href={`mailto: ${entry.email}`} className="link">
            <IonItem>
              <IonText class="cat-text">E-mail</IonText>
              <IonLabel>{entry.email}</IonLabel>
              <IonIcon
                slot="end"
                className="icon-add"
                icon="/assets/icon/icon-44-email.svg"
              />
            </IonItem>
          </a>
        )}

        {/* Homepage */}
        {entry?.homepage && (
          <a href={`${entry.homepage}`} className="link" target="_blank">
            <IonItem>
              <IonText class="cat-text">Homepage</IonText>
              <IonLabel>{entry.homepageLinkText}</IonLabel>
              <IonIcon
                slot="end"
                className="icon-add"
                icon="/assets/icon/icon-43-homepage.svg"
              />
            </IonItem>
          </a>
        )}

        {/* Address */}
        {entry?.address && (
          <IonItem
            style={{ cursor: "pointer" }}
            onClick={() => {
              const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${entry?.address}+${entry?.location}`;
              window.open(googleMapsUrl);
            }}
          >
            <IonText class="cat-text">Adresse</IonText>
            <IonText>{`${entry?.address}, ${entry?.plz} ${entry?.location}`}</IonText>
            <IonIcon
              slot="end"
              className="icon-add"
              icon="/assets/icon/icon-41-standort.svg"
            />
          </IonItem>
        )}
      </IonContent>
    </SafeView>
  );
};

export default observer(SearchDetailChildScreen);
