import { IonList, IonTitle } from "@ionic/react";
import RepoContentCard from "../RepoContentCard/RepoContentCard";
import { observer } from "mobx-react";

import repositoryStore from "../../stores/repository.store";
import { IRepoEntryModel } from "../../models/IRepoEntry.model";

const RepoList: React.FC<any> = () => {
  return (
    <IonList>
      {repositoryStore.entries.length > 0 ? (
        repositoryStore.entries.map((entry: IRepoEntryModel) => {
          return <RepoContentCard key={entry.id} data={entry} />;
        })
      ) : (
        <div className="nores">Keine Einträge vorhanden</div>
      )}
    </IonList>
  );
};

export default observer(RepoList);
