import { IonBadge, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { observer } from "mobx-react";

//style
import "./PhoneBookChip.css";

const PhoneBookChip: React.FC<any> = ({ category }) => {
  const customIcon = category?.attributes;
  const title = category?.attributes?.name;
  const id = category?.id;
  let number = category?.numberOfEntries;

  // if (title === "Alle Kategorien") {
  //   phoneBookStore.categories.forEach((element) => {
  //     number = number + element.numberOfEntries;
  //   });
  // }

  const url = customIcon?.icon?.data?.attributes?.url;

  return (
    <IonItem lines="full" routerLink={`/telefonbuch-inhalt/${id}`}>
      <IonThumbnail slot="start">
        {url && <img src={url} className="phone-bookicon" />}
      </IonThumbnail>
      <IonLabel>{title}</IonLabel>
      <IonBadge slot="end" color="primary" className="chip">
        {number}
      </IonBadge>
    </IonItem>
  );
};

export default observer(PhoneBookChip);
