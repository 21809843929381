import { IonBadge, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { observer } from "mobx-react";
import repositoryStore from "../../stores/repository.store";

//style
import "./RepoChip.css";

const RepoChip: React.FC<any> = ({ category }) => {
  const customIcon = category?.attributes?.icon?.data?.attributes?.url;
  const title = category?.attributes?.name;
  const id = category?.id;
  let number = category?.numberOfEntries;

  if (title === "Alle Kategorien") {
    repositoryStore.categories.forEach((element) => {
      number = number + element.numberOfEntries;
    });
  }

  return (
    <IonItem lines="full" routerLink={`/fundgrube-kategorie/${id}`}>
      <IonThumbnail slot="start">
        <img src={customIcon}></img>
      </IonThumbnail>
      <IonLabel className="title">{title}</IonLabel>
      <IonBadge slot="end" color="primary">
        {number}
      </IonBadge>
    </IonItem>
  );
};

export default observer(RepoChip);
